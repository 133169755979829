import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { EntityTypeEnum } from "@store/entities/entities.model";
import {
  entityDetailsService,
  ManageEntityParametersModel,
  parametersToManageEntityParameters
} from "@store/entities/details";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import ATSConfigComponent from "@screens/auth/admin/entities/parameters/ATSConfig.component";
import GoogleAnalyticsConfigComponent from "@screens/auth/admin/entities/parameters/GoogleAnalyticsConfig.component";
import SellsyConfigComponent from "@screens/auth/admin/entities/parameters/SellsyConfig.component";
import EntityParametersActionsComponent
  from "@screens/auth/admin/entities/components/EntityParametersActions.component";
import BudgetLimitationConfigComponent from "@screens/auth/admin/entities/parameters/BudgetLimitationConfig.component";
import JobBoardsConfigComponent from "@screens/auth/admin/entities/parameters/JobBoardsConfig.component";
import PowerBIConfigComponent from "@screens/auth/admin/entities/parameters/PowerBIConfig.component";
import { AppCountryEnum, getAppCountry } from "@store/common/country.model";
import BoostCreditsConfigComponent from "../parameters/BoostCreditsConfig.component";
import PlanMediaConfigComponent from "../parameters/PlanMediaConfig.component";
import AccessToAltaysConfigComponent from "../parameters/AccessToAltaysConfig.component";
interface EntityParametersModalProps {
  entityId: string;
  handleClose: () => void;
  type: EntityTypeEnum;
}

const EntityParametersModal = (props: EntityParametersModalProps) => {
  const { entityId, handleClose, type } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isInitialised, setIsInitialised] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState<ManageEntityParametersModel>({});

  useEffect(() => {
    entityDetailsService.getEntityDetails(type, entityId).subscribe({
      next: (p) => {
        setParameters(parametersToManageEntityParameters(p));
        setIsInitialised(true);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar, entityId, type]);

  const handleSave = () => {
    setLoading(true);

    entityDetailsService
      .updateEntityParameters(type, entityId, parameters)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("clients.details.success.parameters"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    ats: parameters.atsActivated
      ? {
          type: type === EntityTypeEnum.CLIENT ? getFieldError(parameters.ats?.type, FieldValidationType.REQUIRED_SELECT_ITEM) : undefined,
          url: type === EntityTypeEnum.CLIENT ? getFieldError(parameters.ats?.url, FieldValidationType.REQUIRED_STRING) : undefined,
          companyNames:
            type === EntityTypeEnum.AFFILIATE
              ? parameters.ats?.companyNames?.map((c) => getFieldError(c, FieldValidationType.REQUIRED_STRING))
              : undefined,
        }
      : undefined,
    googleAnalytics: parameters.googleAnalyticsActivated
      ? {
          propertyId: getFieldError(parameters.googleAnalytics?.propertyId, FieldValidationType.REQUIRED_STRING),
        }
      : undefined,
    sellsy: parameters.sellsyActivated
      ? {
          sellsyClientId: getFieldError(parameters.sellsy?.sellsyClientId, FieldValidationType.REQUIRED_STRING),
        }
      : undefined,
  };

  return (
    <ModalComponent
      maxWidth="xs"
      titleLeft
      title={t("clients.details.parameters")}
      handleClose={handleClose}
      content={
        <Box height="60vh" overflow="auto" className="scrollable">
          <Stack py="30px" spacing="30px" alignItems="center" minHeight="100%" justifyContent={!isInitialised ? "center" : "flex-start"}>
            {!isInitialised ? (
              <CircularProgress />
            ) : (
              <>
                <ATSConfigComponent
                  isActivated={parameters.atsActivated}
                  ats={parameters.ats || {}}
                  updateParameters={setParameters}
                  errors={errors}
                  type={type}
                />
                {type === EntityTypeEnum.AFFILIATE && (
                  <>
                    <GoogleAnalyticsConfigComponent
                      isActivated={parameters.googleAnalyticsActivated}
                      googleAnalytics={parameters.googleAnalytics || {}}
                      updateParameters={setParameters}
                      errors={errors}
                    />
                    {getAppCountry() === AppCountryEnum.FR && (
                      <SellsyConfigComponent
                        isActivated={parameters.sellsyActivated}
                        sellsy={parameters.sellsy || {}}
                        updateParameters={setParameters}
                        errors={errors}
                      />
                    )}
                    <BudgetLimitationConfigComponent
                      isActivated={parameters.budgetLimitationActivated}
                      budgetLimitation={parameters.budgetLimitation || {}}
                      updateParameters={setParameters}
                    />
                    <JobBoardsConfigComponent
                      isActivated={parameters.prefilteredJobBoardsActivated}
                      selectedJobBoards={parameters.prefilteredJobBoards || []}
                      updateParameters={setParameters}
                    />
                    <PowerBIConfigComponent isActivated={parameters.powerBIActivated} powerBI={parameters.powerBI} updateParameters={setParameters} />
                    <BoostCreditsConfigComponent
                      isActivated={parameters.boostCreditsActivated}
                      boostCredits={parameters.boostCredits}
                      updateParameters={setParameters}
                    />
                    <PlanMediaConfigComponent
                      isActivated={parameters.planMediaActivated}
                      planMedia={parameters.planMedia}
                      updateParameters={setParameters}
                    />
                    <AccessToAltaysConfigComponent
                      isActivated={parameters.hasAccessToAltays}
                      altaysAutomation={parameters.altaysAutomation}
                      updateParameters={setParameters}
                    />
                  </>
                )}
              </>
            )}
          </Stack>
        </Box>
      }
      actions={
        <Stack direction="row" alignItems="center" spacing="15px">
          <Box width="120px">
            <AIOButtonComponent
              ariaLabel="modal"
              title={t("global.modify")}
              disabled={loading || checkIfErrors(errors)}
              onClick={handleSave}
              variant="contained"
              fullWidth
            />
          </Box>
          {type === EntityTypeEnum.AFFILIATE && <EntityParametersActionsComponent entityId={entityId} disabled={loading || checkIfErrors(errors)} />}
        </Stack>
      }
    />
  );
};

export default EntityParametersModal;
