import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOSelectComponent from "@components/input/Select.component";
import MultiSelectComponent from "@components/input/MultiSelect.component";
import { Colors } from "@constants/colors.constant";
import { InputAdornment, Stack, styled, Typography, Grid } from "@mui/material";
import { ManageMultiDiffusionAd, MultiDiffusionAdStatusDetails, MultiDiffusionSalaryTypeEnum } from "@store/ads";
import {
  CodeTypeExpProFunctions,
  TypeDureeExpProFunctions,
  ContractTypeFunctions,
  CodeTypeExpProEnum,
  HorairesTypeFunctions,
  ConditionsExerciseFunctions,
  SalaryTypeFunctions,
} from "@store/ads/details";
import useMultiDiffusionAdStore from "@store/multiDiffusionAd/multiDiffusionAd.store";
import { useTranslation } from "react-i18next";

const StyledTypography = styled(Typography)({
  color: Colors.grey,
  fontSize: "12px",
  fontWeight: 400,
});

const errorStyle = {
  "& .MuiInputBase-root": {
    border: "1px solid #E1000F3F",
    borderRadius: "10px",
    backgroundColor: "#E1000F0F",
    "& input::placeholder, & textarea::placeholder": {
      color: "#E1000F",
      opacity: 1,
    },
  },
};

const styleMultiSelect = {
  "& .MuiSelect-select": {
    height: "fit-content",
    borderRadius: "10px",
    backgroundColor: "white",
  },
};

const errorStyleMultiSelect = {
  "& .MuiSelect-select": {
    border: "1px solid red",
    borderRadius: "10px",
    backgroundColor: "white",
  },
};

const UpdateAd = () => {
  const { t } = useTranslation();

  const { manageMultiDiffusionAdSelected, setManageMultiDiffusionAdSelected } = useMultiDiffusionAdStore();

  const handleChange = (key: keyof ManageMultiDiffusionAd, value: string | number) => {
    if (!manageMultiDiffusionAdSelected) return;

    setManageMultiDiffusionAdSelected({ ...manageMultiDiffusionAdSelected, [key]: value });

    if (key === "codeTypeExpPro") {
      if (value === CodeTypeExpProEnum.E) {
        requiredFields.push("dureeMinExpPro", "typeDureeExpPro");
      } else {
        requiredFields.splice(requiredFields.indexOf("dureeMinExpPro"), 2);
        requiredFields.splice(requiredFields.indexOf("typeDureeExpPro"), 1);
      }
    }
  };

  const EuroAdornment = (
    <InputAdornment position="end">
      <img src="/images/icon_euro_black.svg" alt="Euro" style={{ width: "9px", height: "17px" }} />
    </InputAdornment>
  );

  const requiredFields = [
    "name",
    "partyId",
    "location",
    "contractType",
    "workingTime",
    "minSalary",
    "description",
    "companyInformation",
    "codeRome",
    "appellationRome",
    "codeTypeExpPro",
    "horairesTypes",
  ];

  const isFieldErrored = (value?: string, key?: keyof ManageMultiDiffusionAd) => {
    if (key && requiredFields.includes(key) && (!value || value?.trim() === "" || value === "0")) return true;

    switch (manageMultiDiffusionAdSelected?.jtmoError?.codeMotif) {
      case key === "workingTime" && 1029:
        return true;
      case key === "location" && 1017:
        return true;
      case key === "region" && 1017:
        return true;
      case key === "department" && 1017:
        return true;
      case key === "minSalary" && -14:
        return true;
      case key === "contractDuration" && -18:
        return true;
      case key === "codeRome" && -103:
        return true;
      case key === "appellationRome" && -103:
        return true;
      case key === "codeTypeExpPro" && -19:
        return true;
      case key === "dureeMinExpPro" && -20:
      case key === "dureeMinExpPro" && -22:
        return true;
      case key === "typeDureeExpPro" && -21:
      case key === "typeDureeExpPro" && -22:
        return true;
      case key === "horairesTypes" && -23:
        return true;
      default:
        return false;
    }
  };

  if (!manageMultiDiffusionAdSelected) return null;

  return (
    <Stack direction="column" width="100%" spacing={2}>
      <Typography sx={{ fontSize: "16px", fontWeight: 700, textAlign: "center" }}>
        {t("multiDiffusion.tab.adStatus.toCorrectModal.updateContent")}
      </Typography>
      {manageMultiDiffusionAdSelected && manageMultiDiffusionAdSelected.jtmoError && (
        <Typography sx={{ fontSize: "16px", fontWeight: 400, textAlign: "center", color: "red", wordBreak: "break-word" }}>
          {manageMultiDiffusionAdSelected.jtmoError.libelleMotif}
        </Typography>
      )}

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <StyledTypography>{t("ads.manageCustom.labels.name")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.name")}
            required
            value={manageMultiDiffusionAdSelected.name}
            onChange={(value) => handleChange("name", value)}
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.name, "name") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTypography>{t("ads.manageCustom.labels.reference")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.reference")}
            required
            value={manageMultiDiffusionAdSelected.reference ?? ""}
            fullWidth
            readonly={true}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.partyId")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.partyId")}
            required
            value={manageMultiDiffusionAdSelected.partyId ?? ""}
            onChange={(value) => handleChange("partyId", value)}
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.partyId ?? "", "partyId") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.codeRome")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.codeRome")}
            required
            value={manageMultiDiffusionAdSelected.codeRome ?? ""}
            onChange={(value) => handleChange("codeRome", value)}
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.codeRome ?? "", "codeRome") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.appellationRome")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.appellationRome")}
            required
            value={manageMultiDiffusionAdSelected.appellationRome ?? ""}
            onChange={(value) => handleChange("appellationRome", value)}
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.appellationRome ?? "", "appellationRome") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.city")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.city")}
            value={manageMultiDiffusionAdSelected.location || ""}
            onChange={(value) => handleChange("location", value)}
            required
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.location ?? "", "location") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.department")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.department")}
            value={manageMultiDiffusionAdSelected.department || ""}
            onChange={(value) => handleChange("department", value)}
            pattern={/^[0-9\-\+\(\)]+$/}
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.department ?? "", "department") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.region")}</StyledTypography>
          <AIOSelectComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.region")}
            value={manageMultiDiffusionAdSelected.region || ""}
            items={MultiDiffusionAdStatusDetails.getRegionOptions()}
            handleChange={(value) => handleChange("region", value)}
            fullWidth
            height="37px"
            errored={isFieldErrored(manageMultiDiffusionAdSelected.region ?? "", "region")}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.contractType")}</StyledTypography>
          <AIOSelectComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.contractType")}
            value={manageMultiDiffusionAdSelected.contractType || ""}
            items={ContractTypeFunctions.selectItems}
            handleChange={(value) => handleChange("contractType", value)}
            fullWidth
            height="37px"
            errored={isFieldErrored(manageMultiDiffusionAdSelected.contractType ?? "", "contractType")}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.workingTime")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.workingTime")}
            value={manageMultiDiffusionAdSelected.workingTime || ""}
            onChange={(value) => handleChange("workingTime", value)}
            pattern={/^[0-9\-\+\(\)]+$/}
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.workingTime ?? "", "workingTime") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.contractDuration")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.contractDuration")}
            value={manageMultiDiffusionAdSelected.contractDuration || ""}
            onChange={(value) => handleChange("contractDuration", value)}
            pattern={/^[0-9\-\+\(\)]+$/}
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.contractDuration ?? "", "contractDuration") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>
            {manageMultiDiffusionAdSelected.salaryType === MultiDiffusionSalaryTypeEnum.A12 ||
            manageMultiDiffusionAdSelected.salaryType === MultiDiffusionSalaryTypeEnum.A13
              ? t("ads.manageCustom.labels.minSalaryAnnual")
              : t("ads.manageCustom.labels.minSalary")}
          </StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            value={manageMultiDiffusionAdSelected.minSalary?.toString() || undefined}
            onChange={(value) => {
              handleChange("minSalary", value);
            }}
            required
            fullWidth
            pattern={/^\d+([.,]\d{0,2})?$/}
            endAdornment={EuroAdornment}
            sx={isFieldErrored(manageMultiDiffusionAdSelected.minSalary?.toString() ?? "", "minSalary") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>
            {manageMultiDiffusionAdSelected.salaryType === MultiDiffusionSalaryTypeEnum.A12 ||
            manageMultiDiffusionAdSelected.salaryType === MultiDiffusionSalaryTypeEnum.A13
              ? t("ads.manageCustom.labels.maxSalaryAnnual")
              : t("ads.manageCustom.labels.maxSalary")}
          </StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            value={manageMultiDiffusionAdSelected.maxSalary?.toString() || undefined}
            onChange={(value) => {
              handleChange("maxSalary", value);
            }}
            pattern={/^\d+([.,]\d{0,2})?$/}
            required
            fullWidth
            endAdornment={EuroAdornment}
            sx={isFieldErrored(manageMultiDiffusionAdSelected.maxSalary?.toString() ?? "", "maxSalary") ? errorStyle : undefined}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.salaryType")}</StyledTypography>
          <AIOSelectComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.salaryType")}
            value={manageMultiDiffusionAdSelected.salaryType || MultiDiffusionSalaryTypeEnum.M13}
            items={SalaryTypeFunctions.selectItems}
            handleChange={(value) => handleChange("salaryType", value)}
            fullWidth
            height="37px"
            errored={isFieldErrored(manageMultiDiffusionAdSelected.salaryType ?? "", "salaryType")}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.codeTypeExpPro")}</StyledTypography>
          <AIOSelectComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.codeTypeExpPro")}
            value={manageMultiDiffusionAdSelected.codeTypeExpPro || ""}
            items={CodeTypeExpProFunctions.selectItems}
            handleChange={(value) => {
              handleChange("codeTypeExpPro", value);
            }}
            fullWidth
            height="37px"
            errored={isFieldErrored(manageMultiDiffusionAdSelected.codeTypeExpPro ?? "", "codeTypeExpPro")}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.dureeMinExpPro")}</StyledTypography>
          <AIOTextfieldComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.dureeMinExpPro")}
            value={manageMultiDiffusionAdSelected.dureeMinExpPro?.toString() || ""}
            onChange={(value) => handleChange("dureeMinExpPro", value)}
            pattern={/^[0-9\-\+\(\)]+$/}
            required
            fullWidth
            sx={isFieldErrored(manageMultiDiffusionAdSelected.dureeMinExpPro?.toString() ?? "", "dureeMinExpPro") ? errorStyle : undefined}
            disabled={manageMultiDiffusionAdSelected.codeTypeExpPro !== CodeTypeExpProEnum.E}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.typeDureeExpPro")}</StyledTypography>
          <AIOSelectComponent
            color="secondary"
            placeholder={t("ads.manageCustom.placeholders.typeDureeExpPro")}
            value={manageMultiDiffusionAdSelected.typeDureeExpPro || ""}
            items={TypeDureeExpProFunctions.selectItems}
            handleChange={(value) => handleChange("typeDureeExpPro", value)}
            fullWidth
            height="37px"
            errored={isFieldErrored(manageMultiDiffusionAdSelected.typeDureeExpPro ?? "", "typeDureeExpPro")}
            readOnly={manageMultiDiffusionAdSelected.codeTypeExpPro !== CodeTypeExpProEnum.E}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.horairesTypes")}</StyledTypography>
          <MultiSelectComponent
            placeholder={t("ads.manageCustom.placeholders.horairesTypes")}
            values={manageMultiDiffusionAdSelected.horairesTypes || []}
            items={HorairesTypeFunctions.selectItems}
            handleChange={(value) => handleChange("horairesTypes", value)}
            fullWidth
            errored={isFieldErrored(manageMultiDiffusionAdSelected.horairesTypes?.join(",") ?? "", "horairesTypes")}
            errorStyle={errorStyleMultiSelect}
            sx={styleMultiSelect}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography>{t("ads.manageCustom.labels.conditionsExercise")}</StyledTypography>
          <MultiSelectComponent
            placeholder={t("ads.manageCustom.placeholders.conditionsExercise")}
            values={manageMultiDiffusionAdSelected.conditionsExercise || []}
            items={ConditionsExerciseFunctions.selectItems}
            handleChange={(value) => handleChange("conditionsExercise", value)}
            fullWidth
            errored={isFieldErrored(manageMultiDiffusionAdSelected.conditionsExercise?.join(",") ?? "", "conditionsExercise")}
            errorStyle={errorStyleMultiSelect}
            sx={styleMultiSelect}
          />
        </Grid>
      </Grid>
      <Stack spacing={1}>
        <StyledTypography>{t("ads.manageCustom.labels.description")}</StyledTypography>
        <AIOTextfieldComponent
          color="secondary"
          placeholder={t("ads.manageCustom.placeholders.description")}
          value={manageMultiDiffusionAdSelected.description || ""}
          onChange={(value) => handleChange("description", value)}
          fullWidth
          multiline
          minRows={6}
          sx={isFieldErrored(manageMultiDiffusionAdSelected.description ?? "", "description") ? errorStyle : undefined}
        />
      </Stack>
      <Stack spacing={1}>
        <StyledTypography>{t("ads.manageCustom.labels.profile")}</StyledTypography>
        <AIOTextfieldComponent
          color="secondary"
          placeholder={t("ads.manageCustom.placeholders.profile")}
          value={manageMultiDiffusionAdSelected.profile || ""}
          onChange={(value) => handleChange("profile", value)}
          fullWidth
          multiline
          minRows={6}
          sx={isFieldErrored(manageMultiDiffusionAdSelected.profile ?? "", "profile") ? errorStyle : undefined}
        />
      </Stack>
      <Stack spacing={1}>
        <StyledTypography>{t("ads.manageCustom.labels.companyInformation")}</StyledTypography>
        <AIOTextfieldComponent
          color="secondary"
          placeholder={t("ads.manageCustom.placeholders.companyInformation")}
          value={manageMultiDiffusionAdSelected.companyInformation || ""}
          onChange={(value) => handleChange("companyInformation", value)}
          fullWidth
          multiline
          minRows={6}
          sx={isFieldErrored(manageMultiDiffusionAdSelected.companyInformation ?? "", "companyInformation") ? errorStyle : undefined}
        />
      </Stack>
      <Stack spacing={1}>
        <StyledTypography>{t("ads.manageCustom.labels.advantages")}</StyledTypography>
        <AIOTextfieldComponent
          color="secondary"
          placeholder={t("ads.manageCustom.placeholders.advantages")}
          value={manageMultiDiffusionAdSelected.advantages || ""}
          onChange={(value) => handleChange("advantages", value)}
          fullWidth
          multiline
          minRows={6}
          sx={isFieldErrored(manageMultiDiffusionAdSelected.advantages ?? "", "advantages") ? errorStyle : undefined}
        />
      </Stack>
    </Stack>
  );
};

export default UpdateAd;
