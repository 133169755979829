import ModalComponent from "@components/modal/Modal.component";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { StyledTextDescribeModal, StyledTitleModal } from "./ConfirmPublishAds.modal";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { FranceTravailAnnulationMotifEnum } from "@store/multiDiffusionAd/multiDiffusionAd.model";
import { useState } from "react";
import { SelectItem } from "@components/input/Select.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import { useSnackbar } from "notistack";
import { adsService } from "@store/ads";

interface CloseAdsModalProps {
  handleClose: () => void;
}

const CloseAdsModal: React.FC<CloseAdsModalProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const [selectedMotif, setSelectedMotif] = useState<SelectItem | undefined>(undefined);
  const [adsToClose, setAdsToClose] = useState<string>("");
  const [loadingCloseAds, setLoadingCloseAds] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const motifs = Object.values(FranceTravailAnnulationMotifEnum).map((motif) => ({
    label: t(`multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.motifs.${motif}`),
    value: motif,
  }));

  const handleCloseAds = () => {
    if (!selectedMotif || !adsToClose) return;
    const adsReferences = adsToClose.replaceAll(" ", "").split(",");
    setLoadingCloseAds(true);
    adsService.closeMultiDiffusionAds(adsReferences, selectedMotif.value).subscribe({
      next: (responses) => {
        setLoadingCloseAds(false);
        if (responses.length > 0 && responses.some((response) => !!response?.value?.libelleMotif)) {
          enqueueSnackbar(
            `${t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdsModal.error")} ${responses
              .map((response, index) =>
                response?.value?.libelleMotif ? `[ref: ${adsReferences[index]}] ${response?.value?.libelleMotif}` : undefined
              )
              .filter(Boolean)
              .join(", \n")}`,
            { variant: "error", autoHideDuration: 10000 }
          );
        } else {
          enqueueSnackbar(t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdsModal.success"), { variant: "success" });
        }
        handleClose();
      },
      error: () => setLoadingCloseAds(false),
    });
  };

  return (
    <ModalComponent
      handleClose={() => handleClose()}
      titleLeft={false}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      ariaLabel="Modal exemple"
      textAlign="center"
      content={
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mb={3} mt={2}>
          <img src="/images/icon_closeAd_primary.svg" alt="check" style={{ width: "56px", height: "47px", marginBottom: "1rem" }} />
          <StyledTitleModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdsModal.title")}</StyledTitleModal>
          <StyledTextDescribeModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdsModal.description")}</StyledTextDescribeModal>
          <Stack direction="column" spacing={2} width="100%" minWidth="200px">
            <SelectWithSearchComponent
              items={motifs}
              placeholder={t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.selectMotif")}
              handleChange={(value) => setSelectedMotif(value)}
              value={selectedMotif}
            />
            <AIOTextfieldComponent
              title={t("ads.details.modals.publish.select_references")}
              value={adsToClose}
              onChange={(value) => setAdsToClose(value)}
              placeholder={t("ads.details.modals.publish.select_references_placeholder")}
            />
          </Stack>
        </Stack>
      }
      actions={
        <Stack direction="row" spacing={2} justifyContent="center">
          <AIOButtonComponent
            disabled={loadingCloseAds || !selectedMotif || !adsToClose}
            variant="contained"
            onClick={handleCloseAds}
            title={t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdsModal.title")}
          />
          <AIOButtonComponent
            ariaLabel="light"
            onClick={() => {
              handleClose();
            }}
            title={t("global.cancel")}
          />
        </Stack>
      }
    />
  );
};

export default CloseAdsModal;
