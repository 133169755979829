import { catchError, finalize, from, map, Observable, switchMap, tap } from "rxjs";
import { addEntities, deleteAllEntities, deleteEntities, getEntitiesIds, UIEntitiesRef, upsertEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";

import APIAxios, { APIRoutes } from "@api/axios.api";
import { SelectItem } from "@components/input/Select.component";
import {
  AdModel,
  AdsFilters,
  AdsSort,
  AdStats,
  AdsToPublishFilters,
  JtmoErrorDto,
  ManageMultiDiffusionAd,
  MultiDiffusionAdMediaEnum,
  MultiDiffusionAdModel,
  MultiDiffusionAdsFilters,
  MultiDiffusionAdStats,
  MultiDiffusionAdStatusEnum,
} from "@store/ads/ads.model";
import { getAdsDataSource, getAdsStatsDataSource, getPaginatedAdsDataSource } from "@store/ads/ads.requests";
import { adsStore, PaginationDataFromServer } from "@store/ads/ads.store";
import { Candidate, candidatesService } from "@store/ai-o/candidates";
import { sessionQuery } from "@store/session";
import SnackError from "@utils/error.utils";
import { PaginatedData } from "@utils/infinitescroll.utils";
import { ContractTypeEnum } from "./details";
import { FranceTravailAnnulationMotifEnum } from "@store/multiDiffusionAd/multiDiffusionAd.model";

export class AdsService {
  store = adsStore;

  resetStore = () => this.store.reset();
  deleteEntities = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());
  removeOneEntity = (id: string) => this.store.update(deleteEntities([id]));

  setFilters = (filters: Partial<AdsFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setSort = (sort: Partial<AdsSort>) =>
    this.store.update((state) => ({
      ...state,
      sort: {
        ...state.sort,
        ...sort,
      },
    }));

  updateAdsToPublishFilters(filters: Partial<AdsToPublishFilters>) {
    this.store.update((state) => ({
      ...state,
      adsToPublishFilters: {
        ...state.adsToPublishFilters,
        ...filters,
      },
    }));
  }

  setMultiDiffusionFilters = (filters: Partial<MultiDiffusionAdsFilters>) =>
    this.store.update((state) => ({
      ...state,
      multiDiffusionAdsFilters: {
        ...state.multiDiffusionAdsFilters,
        ...filters,
      },
    }));

  updateAdsToPublishSelected = (ads: AdModel[]) =>
    this.store.update((state) => ({
      ...state,
      adsToPublishSelected: ads,
    }));

  getAds = (filters?: AdsFilters, sort?: AdsSort, page: number = 1, take: number = 24): Observable<PaginatedData<AdModel[]>> => {
    const affiliateIds = filters?.affiliateIds
      ? filters.affiliateIds.map((c) => c.value)
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(
      APIAxios({
        ...APIRoutes.GETAds(),
        params: {
          search: filters?.search || undefined,
          isArchived: !!filters?.isArchived,
          aioCandidateJobName: filters?.aioCandidateJobName || undefined,
          aioCandidateJobLocation: filters?.aioCandidateJobLocation || undefined,
          aioCandidateJobReference: filters?.aioCandidateJobReference || undefined,
          affiliateIds,
          favorite: sessionQuery.affiliateIds?.some((c) => c.value === "FAVORITES") || undefined,
          sort: sort?.field,
          order: sort?.field ? "ASC" : undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<AdModel[]>>) => {
        return response.data;
      }),
      tap((ads) => {
        this.store.update(
          upsertEntities(ads.data),
          updatePaginationData({
            currentPage: ads.meta.page,
            perPage: ads.meta.take,
            total: ads.meta.itemCount,
            lastPage: ads.meta.pageCount,
          }),
          setPage(
            ads.meta.page,
            ads.data.map((ad) => ad.id)
          ),
          upsertEntities(
            ads.data
              .filter((ad) => !this.store.query(getEntitiesIds()).includes(ad.id))
              .map((ad) => ({
                ...ad,
                selected: false,
              })),
            { ref: UIEntitiesRef }
          ),
          getAdsDataSource.setSuccess()
        );
      }),
      getAdsDataSource.trackRequestStatus()
    );
  };

  getFilteredAdsToPublish(filters: AdsToPublishFilters, updateStore: boolean = true): Observable<AdModel[]> {
    const params = {
      affiliateIds: filters.affiliateIds.map((c) => c.value),
      contractType: filters.contractType.map((ct) => ct.value as ContractTypeEnum),
      location: filters.location.map((l) => l.value),
      hasNotBeenPublishedOn: [MultiDiffusionAdMediaEnum.FRANCE_TRAVAIL], // TODO: add other media for the future evolution
      ...(filters.commaSeparatedReferences ? { commaSeparatedReferences: filters.commaSeparatedReferences } : {}),
    };

    this.store.update((state) => ({
      ...state,
      loadingAdsToPublish: true,
    }));

    return from(APIAxios({ ...APIRoutes.GETAdsToPusblish(), params })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdModel[]>) => {
        return response.data;
      }),
      tap((filteredAds) => {
        if (updateStore) {
          this.store.update((state) => ({
            ...state,
            adsToPublish: filteredAds,
          }));
        }
      }),
      finalize(() => {
        this.store.update((state) => ({
          ...state,
          loadingAdsToPublish: false,
        }));
      })
    );
  }

  getMultiDiffusionAds(
    filters: MultiDiffusionAdsFilters,
    page: number = 1,
    take: number = 24
  ): Observable<{ data: MultiDiffusionAdModel[]; meta: PaginationDataFromServer }> {
    const params = {
      status: filters.status,
      search: filters.search,
      favorites: filters.affiliateIds.some((c) => c.value === "FAVORITES") || undefined,
      affiliateIds: filters.affiliateIds.some((c) => c.value === "FAVORITES") ? undefined : filters.affiliateIds.map((c) => c.value),
      page,
      take,
    };

    return from(APIAxios({ ...APIRoutes.GETMultiDiffusionAds(), params })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<{ data: MultiDiffusionAdModel[]; meta: PaginationDataFromServer }>) => {
        return response.data;
      }),
      tap((multiDiffusionAds) => {
        this.store.update((state) => ({
          ...state,
          multiDiffusionAds,
        }));
      })
    );
  }

  getMultiDiffusionAdsFromOriginalAnnouncement(originalAnnouncementId: string): Observable<MultiDiffusionAdModel[]> {
    return from(APIAxios({ ...APIRoutes.GETMultiDiffusionAdsFromOriginalAnnouncement(originalAnnouncementId) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<MultiDiffusionAdModel[]>) => {
        return response.data;
      })
    );
  }

  getMultiDiffusionAdsStats = (filters: MultiDiffusionAdsFilters): Observable<MultiDiffusionAdStats> => {
    const params = {
      favorites: filters.affiliateIds.some((c) => c.value === "FAVORITES") || undefined,
      affiliateIds: filters.affiliateIds.some((c) => c.value === "FAVORITES") ? undefined : filters.affiliateIds.map((c) => c.value),
    };

    return from(APIAxios({ ...APIRoutes.GETMultiDiffusionAdsStats(), params })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<MultiDiffusionAdStats>) => response.data),
      tap((stats) => {
        this.store.update((state) => ({
          ...state,
          multiDiffusionAdsStats: stats,
        }));
      })
    );
  };

  publishAds = (announcementIds: string[], medias: MultiDiffusionAdMediaEnum[], programedAt?: Date): Observable<AxiosResponse> => {
    return from(APIAxios({ ...APIRoutes.POSTPublishAds(), data: { announcementIds, medias, programedAt } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse) => response.data)
    );
  };

  publishCorrectedMultiDiffusionAd = (multiDiffusionAd: ManageMultiDiffusionAd): Observable<AxiosResponse> => {
    return from(APIAxios({ ...APIRoutes.POSTPublishCorrectMultiDiffusionAd(multiDiffusionAd.id), data: multiDiffusionAd })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse) => response.data),
      tap(() => {
        this.store.update((state) => ({
          ...state,
          multiDiffusionAds: {
            ...state.multiDiffusionAds,
            data: state.multiDiffusionAds.data.map((ad) =>
              ad.id === multiDiffusionAd.id ? { ...ad, status: MultiDiffusionAdStatusEnum.PENDING } : ad
            ),
          },
        }));
      })
    );
  };

  editMultiDiffusionAd = (multiDiffusionAd: ManageMultiDiffusionAd): Observable<MultiDiffusionAdModel> => {
    return from(APIAxios({ ...APIRoutes.PUTEditMultiDiffusionAd(multiDiffusionAd.id), data: multiDiffusionAd })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<MultiDiffusionAdModel>) => response.data),
      tap((multiDiffusionAd) => {
        this.store.update((state) => ({
          ...state,
          multiDiffusionAds: {
            ...state.multiDiffusionAds,
            data: state.multiDiffusionAds.data.map((ad) => (ad.id === multiDiffusionAd.id ? multiDiffusionAd : ad)),
          },
        }));
      })
    );
  };

  refreshMultiDiffusionAd = (multiDiffusionAd: ManageMultiDiffusionAd): Observable<JtmoErrorDto[]> => {
    return from(APIAxios({ ...APIRoutes.PUTRefreshMultiDiffusionAd(multiDiffusionAd.id), data: multiDiffusionAd })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<JtmoErrorDto[]>) => response.data)
    );
  };

  refreshMultiDiffusionAds = (multiDiffusionAdReferences: string[]): Observable<JtmoErrorDto[]> => {
    return from(APIAxios({ ...APIRoutes.PUTRefreshMultiDiffusionAds(), data: { multiDiffusionAdReferences } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<JtmoErrorDto[]>) => response.data)
    );
  };

  closeMultiDiffusionAd = (multiDiffusionAd: ManageMultiDiffusionAd, motif: FranceTravailAnnulationMotifEnum): Observable<JtmoErrorDto> => {
    return from(APIAxios({ ...APIRoutes.PUTCloseMultiDiffusionAd(multiDiffusionAd.id), data: { motif } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<JtmoErrorDto>) => response.data)
    );
  };

  closeMultiDiffusionAds = (
    multiDiffusionAdReferences: string[],
    motif: FranceTravailAnnulationMotifEnum
  ): Observable<{ status: string; value: JtmoErrorDto | null }[]> => {
    return from(APIAxios({ ...APIRoutes.PUTCloseMultiDiffusionAds(), data: { multiDiffusionAdReferences, motif } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<{ status: string; value: JtmoErrorDto | null }[]>) => response.data)
    );
  };

  deleteMultiDiffusionAd = (multiDiffusionAdId: string) => {
    return from(APIAxios({ ...APIRoutes.DELETEMultiDiffusionAd(multiDiffusionAdId) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  getAdsWithStats = (filters?: AdsFilters, sort?: AdsSort, page: number = 1, take: number = 24): Observable<PaginatedData<AdModel[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdsWithStats(),
        params: {
          search: filters?.search || undefined,
          isArchived: filters?.isArchived || false,
          affiliateIds: filters?.affiliateIds?.map((c) => c.value),
          sort: sort?.field,
          order: sort?.field ? "ASC" : undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<AdModel[]>>) => {
        return response.data;
      }),
      tap((ads) => {
        this.store.update(
          addEntities(ads.data.filter((ad) => !this.store.query(getEntitiesIds()).includes(ad.id))),
          updatePaginationData({
            currentPage: ads.meta.page,
            perPage: ads.meta.take,
            total: ads.meta.itemCount,
            lastPage: ads.meta.pageCount,
          }),
          setPage(
            ads.meta.page,
            ads.data.map((ad) => ad.id)
          ),
          getPaginatedAdsDataSource.setSuccess()
        );
      }),
      getPaginatedAdsDataSource.trackRequestStatus()
    );
  };

  getAdsWithStatsNoStore = (filters?: AdsFilters, sort?: AdsSort, page: number = 1, take: number = 24): Observable<PaginatedData<AdModel[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdsWithStats(),
        params: {
          search: filters?.search || undefined,
          affiliateIds: filters?.affiliateIds?.map((c) => c.value),
          sort: sort?.field,
          order: sort?.field ? "ASC" : undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<AdModel[]>>) => {
        return response.data;
      })
    );
  };

  getAdsReportsCSV = (filters?: AdsFilters): Observable<Blob> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdsReportsCSV(),
        params: {
          search: filters?.search || undefined,
          affiliateIds: filters?.affiliateIds?.map((c) => c.value),
        },
        responseType: "blob",
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  getAdsStats = (filters?: AdsFilters): Observable<AdStats> => {
    const affiliateIds = filters?.affiliateIds
      ? filters.affiliateIds.map((c) => c.value)
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(
      APIAxios({
        ...APIRoutes.GETAdsStats(),
        params: {
          affiliateIds,
          favorite: sessionQuery.affiliateIds?.some((c) => c.value === "FAVORITES") || undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdStats>) => {
        return response.data;
      }),
      tap((stats) => {
        this.store.update(
          (state) => ({
            ...state,
            stats,
          }),
          getAdsStatsDataSource.setSuccess()
        );
      }),
      getAdsStatsDataSource.trackRequestStatus()
    );
  };

  updateAdsDiffusionList = (adReferences: string[], jobBoards: SelectItem[], affiliateId: string): Observable<{ modifiedCount: number }> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAdsDiffusion(),
        data: { adReferences, jobBoards: jobBoards.map((j) => j.value), affiliateId },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<{ modifiedCount: number }>) => {
        return response.data;
      })
    );
  };

  createAIOApplication = (announcementId: string, announcementRef: string, candidate: Candidate): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAIOApplication(),
        data: {
          announcement: announcementId,
          aioId: candidate.id,
          candidate: candidate.candidateInfo ? `${candidate.candidateInfo.firstName} ${candidate.candidateInfo.lastName}` : undefined,
          applicationDate: candidate.creationDate,
          cvKey: candidate.cvKey,
          linkedinProfileURL: candidate.linkedin,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => candidatesService.reattachAnnouncement(candidate.id, announcementId, announcementRef))
    );
  };
}

export const adsService = new AdsService();
