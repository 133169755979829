import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import ModalComponent from "@components/modal/Modal.component";
import { StyledTextDescribeModal, StyledTitleModal } from "./ConfirmPublishAds.modal";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import { useState } from "react";
import { adsService } from "@store/ads";
import { useSnackbar } from "notistack";
interface RefreshAdsModalProps {
  handleClose: () => void;
}

const RefreshAdsModal: React.FC<RefreshAdsModalProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingRefreshAds, setLoadingRefreshAds] = useState(false);
  const [adsToRefresh, setAdsToRefresh] = useState<string>("");

  const handleRefreshAds = () => {
    if (!adsToRefresh) return;
    const references = adsToRefresh.replaceAll(" ", "").split(",");
    setLoadingRefreshAds(true);
    adsService.refreshMultiDiffusionAds(references).subscribe({
      next: (response) => {
        if (response.length > 0 && response.some((error) => error.libelleMotif !== "Success")) {
          enqueueSnackbar(
            `${t("multiDiffusion.tab.adStatus.toCorrectModal.refreshAdsModal.error")} ${response
              .map((error, index) => `[ref: ${references[index]}] : ${error.libelleMotif}`)
              .filter(Boolean)
              .join(", \n")}`,
            { variant: "error", autoHideDuration: 10000 }
          );
        }
        setLoadingRefreshAds(false);
        handleClose();
      },
      error: () => setLoadingRefreshAds(false),
    });
  };

  return (
    <ModalComponent
      handleClose={() => handleClose()}
      fullWidth={true}
      maxWidth="sm"
      titleLeft={false}
      scroll="paper"
      ariaLabel="Modal exemple"
      textAlign="center"
      content={
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2} mb={3} mt={2}>
          <img src="/images/icon_refresh_blue.svg" alt="check" style={{ width: "56px", height: "47px", marginBottom: "1rem" }} />
          <StyledTitleModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.refreshAdsModal.title")}</StyledTitleModal>
          <StyledTextDescribeModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.refreshAdsModal.description")}</StyledTextDescribeModal>
          <Stack width="50%">
            <AIOTextfieldComponent
              title={t("ads.details.modals.publish.select_references")}
              value={adsToRefresh}
              onChange={(value) => setAdsToRefresh(value)}
              placeholder={t("ads.details.modals.publish.select_references_placeholder")}
            />
          </Stack>
        </Stack>
      }
      actions={
        <Stack direction="row" spacing={2} justifyContent="center">
          <AIOButtonComponent
            disabled={loadingRefreshAds || !adsToRefresh}
            variant="contained"
            onClick={handleRefreshAds}
            title={t("multiDiffusion.tab.adStatus.toCorrectModal.refreshAdModal.title")}
          />
          <AIOButtonComponent ariaLabel="light" onClick={() => handleClose()} title={t("global.cancel")} />
        </Stack>
      }
    />
  );
};

export default RefreshAdsModal;
