import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { alpha, Box, CircularProgress, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { bindHover, bindPopover } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { AiApplicationEvaluationResponse, AiApplicationInEvaluation, Application, EvaluationStatusEnum } from "@store/applications";
import { subscriptions, subscriptionsQuery, SubscriptionTypeEnum } from "@store/subscriptions";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { AdDetailsApplicationsList, adDetailsService } from "@store/ads/details";

import { Colors } from "@constants/colors.constant";

import useCountDown from "@utils/hooks/useLocalStorageCountDown.utils";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import SeeSubscriptionsModal from "@components/subscriptions/modal/SeeSubscriptions.modal";
import ConfirmModal from "@components/modal/Confirm.modal";
import HoverPopover from "material-ui-popup-state/HoverPopover";

const StyledAiSortedStatusCard = styled(Stack)({
  width: "100%",
  minWidth: "250px",
  padding: "15px 20px",
  borderRadius: "15px",
  backgroundColor: Colors.greyCardBg,
});

const StyledEvaluatingPopover = styled(HoverPopover)({
  "& .MuiPaper-root": {
    borderRadius: 15,
    backgroundColor: "white",
    boxShadow: `0 8px 20px 0 ${alpha(Colors.black, 0.1)}`,
    padding: "20px 20px 0",
  },
});

const SORT_ANIMATION_DURATION = 1200;
const SORT_LIMITATION_DURATION = 60; // in seconds

interface AIApplicationsEvaluationButtonProps {
  applicationsByCategoryState?: AdDetailsApplicationsList[];
  evaluableApplications: Application[];
  handleAutomaticallyMoved: (newCategory: string, application: AiApplicationEvaluationResponse) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  toBeProcessedApplications: number;
  updateInAnimationApplications: Dispatch<SetStateAction<string[]>>;
}

const AIApplicationsEvaluationButton = (props: AIApplicationsEvaluationButtonProps) => {
  const {
    applicationsByCategoryState = [],
    evaluableApplications,
    handleAutomaticallyMoved,
    loading,
    setLoading,
    toBeProcessedApplications,
    updateInAnimationApplications,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const evaluatedApplicationsDetailsPopupState = usePopupState({ variant: "popover", popupId: "evaluated-applications" });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [showFreemiumModal, setShowFreemiumModal] = useState(false);
  const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false);

  const [evaluatedApplications, setEvaluatedApplications] = useState<AiApplicationInEvaluation[]>([]);
  const [aiEvaluationJustCompleted, setAiEvaluationJustCompleted] = useState(false);

  const { countDown, countDownStarted, startCountDown } = useCountDown("aiEvaluationCountDown", true);

  useEffect(() => {
    if (aiEvaluationJustCompleted) {
      setAiEvaluationJustCompleted(false);
      if (evaluatedApplications.some((a) => a.aiEvaluationStatus === EvaluationStatusEnum.SUCCESS)) {
        setShowSuccessModal(true);
      } else {
        enqueueSnackbar(t("ads.details.applyTab.aiSorting.aiError"), { variant: "error" });
      }
    }
  }, [aiEvaluationJustCompleted, enqueueSnackbar, evaluatedApplications, t]);

  const ad = useMemo(() => {
    return evaluableApplications[0]?.announcement;
  }, [evaluableApplications]);

  const handleAiEvaluateClick = () => {
    openConfirmOrFreemiumModal();
  };

  const openConfirmOrFreemiumModal = () => {
    if (sessionQuery.user.role === UserRoleEnum.ADMIN || subscriptionsQuery.sessionUserSubscriptionOngoing || ad?.managedByTwinin) {
      if (!loading) {
        setShowConfirmModal(true);
      }
    } else {
      setShowFreemiumModal(true);
    }
  };

  const handleAiEvaluate = () => {
    setLoading(true);
    setEvaluatedApplications(evaluableApplications.map((a) => ({ ...a, aiEvaluation: undefined, aiEvaluationStatus: EvaluationStatusEnum.LOADING })));

    adDetailsService.aiEvaluateApplications(evaluableApplications.map((a) => a.id)).subscribe({
      next: (response) => {
        if (response.sentToAltays === true) {
          enqueueSnackbar(
            t("applications.sendToAltaysSuccessWithName", {
              name: response.application?.candidate?.fullName || response.application?.customName || response.application?.name,
            }),
            { variant: "success", autoHideDuration: 10000 }
          );
        } else if (response.sentToAltays === false) {
          enqueueSnackbar(
            t("applications.sendToAltaysErrorWithName", {
              name: response.application?.candidate?.fullName || response.application?.customName || response.application?.name,
            }),
            { variant: "error", autoHideDuration: 10000 }
          );
        }

        setEvaluatedApplications((state) =>
          state.map((a) => {
            const currentApplicationId = response.erroredApplicationId || response.application?.id;
            return a.id === currentApplicationId
              ? {
                  ...a,
                  ...response.application,
                  aiEvaluation: response,
                  aiEvaluationStatus: response.error || response.erroredApplicationId ? EvaluationStatusEnum.ERROR : EvaluationStatusEnum.SUCCESS,
                }
              : a;
          })
        );

        // CHECK IF ERROR
        if (response.error || response.erroredApplicationId || !response.applicationList) return;

        // CHECK IF CATEGORY EXISTS
        const currentList = applicationsByCategoryState.find((c) => c.name === response.applicationList);
        if (!currentList) return;

        // CHECK IF APPLICATION NOT ALREADY IN CATEGORY
        if (!response.application || currentList.applications.some((a) => a.id === response.application?.id)) return;

        const currentCard = document.getElementById(response.application.id);
        const destinationColumn = document.getElementById(currentList.name);
        if (!currentCard || !destinationColumn) return;

        const cardBoundingRect = currentCard.getBoundingClientRect();
        const destinationColumnBoundingRect = destinationColumn.getBoundingClientRect();

        const clone = currentCard.cloneNode(true) as HTMLElement;
        document.body?.appendChild(clone);
        clone.style.cssText = `
          position: fixed;
          width: ${cardBoundingRect.width}px;
          height: ${cardBoundingRect.height}px;
          z-index: 1000;
          pointer-events: none;
          left: ${cardBoundingRect.x}px;
          top: ${cardBoundingRect.y}px;
          transform: translate(0px, 0px);
          transition: transform ${SORT_ANIMATION_DURATION}ms ease;
        `;

        updateInAnimationApplications((prev) => [...prev, response.application!.id]);

        clone.addEventListener("transitionend", () => {
          clone.style.opacity = "1";
          clone.style.transform = clone.style.transform + " scale(1)";
          clone.style.transition = "all 300ms ease";

          requestAnimationFrame(() => {
            clone.style.opacity = "0";
            clone.style.transform = clone.style.transform.replace("scale(1)", "scale(0)");
          });

          setTimeout(() => clone.remove(), 300);

          updateInAnimationApplications((prev) => prev.filter((x) => x !== response.application!.id));

          handleAutomaticallyMoved(response.applicationList!, response);
        });

        requestAnimationFrame(() => {
          clone.style.transform = `translate(${destinationColumnBoundingRect?.x - cardBoundingRect.width}px, ${
            destinationColumnBoundingRect.y - clone.offsetTop + 70 // 70 is the padding of the column
          }px)`;
        });
      },
      complete: () => {
        setLoading(false);
        setAiEvaluationJustCompleted(true);
        evaluatedApplicationsDetailsPopupState.close();
        startCountDown(SORT_LIMITATION_DURATION);

        if (ad?.id) {
          adDetailsService.getAdApplications(ad.id).subscribe({
            error: (err) => enqueueSnackbar(err.text, err.options),
          });
        }
      },
      error: (err) => {
        setLoading(false);
        enqueueSnackbar(err.text, err.options);
      },
    });
  };

  return (
    <>
      {!!ad?.description && !!evaluableApplications.length && (
        <Box whiteSpace="nowrap" {...(loading ? bindHover(evaluatedApplicationsDetailsPopupState) : {})}>
          <AIOButtonComponent
            onClick={handleAiEvaluateClick}
            disabled={!evaluableApplications.length || countDownStarted}
            icon={loading ? <CircularProgress size={16} color="inherit" /> : undefined}
            title={
              loading
                ? t("ads.details.applyTab.aiSorting.inSort", {
                    number: `${evaluatedApplications.filter((x) => x.aiEvaluationStatus !== EvaluationStatusEnum.LOADING).length}/${
                      evaluatedApplications.length
                    }`,
                  })
                : `${t("ads.details.applyTab.aiSorting.toSortByAi")}${
                    countDownStarted ? ` (${t("ads.details.applyTab.aiSorting.timeLeft", { count: countDown })})` : ""
                  }`
            }
            variant="contained"
          />
        </Box>
      )}
      <StyledEvaluatingPopover
        {...bindPopover(evaluatedApplicationsDetailsPopupState)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ pointerEvents: "none" }}
        disableRestoreFocus>
        <Stack overflow="auto" maxHeight="60vh">
          <Typography fontWeight={700} fontSize="16px">
            {t("ads.details.applyTab.aiSorting.inSort", {
              number: `${evaluatedApplications.filter((x) => x.aiEvaluationStatus !== EvaluationStatusEnum.LOADING).length}/${
                evaluatedApplications.length
              }`,
            })}
          </Typography>
          <Stack spacing="10px" className="scrollable" py="20px" overflow="auto">
            {evaluatedApplications.map((application) => (
              <StyledAiSortedStatusCard key={application.id} direction="row" justifyContent="space-between" alignItems="center" spacing="30px">
                <Stack spacing="2px">
                  <Typography fontWeight={400} fontSize="14px" lineHeight="1.25">
                    {application.customName || application.candidate?.fullName || application.name}
                  </Typography>
                  <Typography fontWeight={300} fontSize="14px" lineHeight="1.25">
                    {application.aiEvaluationStatus === EvaluationStatusEnum.LOADING
                      ? t("ads.details.applyTab.aiSorting.inProgress")
                      : application.aiEvaluationStatus === EvaluationStatusEnum.ERROR
                      ? t("ads.details.applyTab.aiSorting.error")
                      : t("ads.details.applyTab.aiSorting.sortedIn", {
                          list: t(`ads.details.applyTab.lists.${application.aiEvaluation?.applicationList || "UNKNOWN"}`),
                        })}
                  </Typography>
                </Stack>
                <Stack justifyContent="center" alignItems="center" width="20px" height="20px">
                  {application.aiEvaluationStatus === EvaluationStatusEnum.LOADING ? (
                    <CircularProgress size="17px" />
                  ) : application.aiEvaluationStatus === EvaluationStatusEnum.SUCCESS ? (
                    <img width="17px" alt="success" src="/images/icon_success.svg" />
                  ) : (
                    <img width="17px" alt="error" src="/images/icon_error.svg" />
                  )}
                </Stack>
              </StyledAiSortedStatusCard>
            ))}
          </Stack>
        </Stack>
      </StyledEvaluatingPopover>
      {showConfirmModal && (
        <ConfirmModal
          modalTitle={t("ads.details.applyTab.aiSorting.sortByAiModalTitle")}
          confirmMsg={t("ads.details.applyTab.aiSorting.sortByAiModalMsg")}
          hideCancel
          handleConfirm={handleAiEvaluate}
          handleClose={() => setShowConfirmModal(false)}
          logo={<img width="60px" alt="robot" src="/images/ia_robot.svg" />}
        />
      )}
      {showSuccessModal && (
        <ModalComponent
          handleClose={() => setShowSuccessModal(false)}
          icon={<img alt="check" src="/images/illus_check.svg" />}
          title={t("ads.details.applyTab.aiSorting.successModal.title")}
          content={
            <Stack px="50px" py="20px">
              <Typography textAlign="center" pb="25px" lineHeight="1.25" whiteSpace="pre-line" color={Colors.secondaryText} fontWeight={300}>
                {toBeProcessedApplications > 0
                  ? t("ads.details.applyTab.aiSorting.successModal.descriptionCvLeft", { number: toBeProcessedApplications })
                  : t("ads.details.applyTab.aiSorting.successModal.description")}
              </Typography>
            </Stack>
          }
          maxWidth="xs"
          paddingButton="10px 0px 40px 0px"
        />
      )}
      {showFreemiumModal && (
        <>
          <ModalComponent
            handleClose={() => setShowFreemiumModal(false)}
            title={t(`ads.details.applyTab.aiSorting.sortByAiModalTitle`)}
            icon={<img width={60} alt="warning" src="/images/illus_warning.png" />}
            content={
              <Stack mt="20px" spacing={3} px="50px" pb="20px">
                <Typography textAlign="center" lineHeight={1.5}>
                  {t(`ads.details.applyTab.aiSorting.notForFreemium`)}
                </Typography>
              </Stack>
            }
            actions={
              <AIOButtonComponent
                title={t("subscriptions.seeSubscriptions")}
                variant="contained"
                ariaLabel="modal"
                onClick={() => setShowSubscriptionsModal(true)}
              />
            }
            maxWidth="sm"
          />
          {showSubscriptionsModal && (
            <SeeSubscriptionsModal
              subscriptions={subscriptions.filter((s) => s.type === SubscriptionTypeEnum.COMPANY || s.type === SubscriptionTypeEnum.PROFESSIONAL)}
              handleClose={() => setShowSubscriptionsModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default AIApplicationsEvaluationButton;
