import { AdModel, MultiDiffusionAdModel } from "@store/ads";
import { create } from "zustand";

export interface MultiDiffusionAdState {
  chosenAd: AdModel | null;
  setChosenAd: (ad: AdModel | null) => void;
  modalTypeOpened: MultiDiffusionModalEnum | null;
  setModalTypeOpened: (modalType: MultiDiffusionModalEnum | null) => void;
  manageMultiDiffusionAdSelected: MultiDiffusionAdModel | null;
  setManageMultiDiffusionAdSelected: (ad: MultiDiffusionAdModel | null) => void;
  loadingPublish: boolean;
  setLoadingPublish: (loading: boolean) => void;
}

export enum MultiDiffusionModalEnum {
  CORRECT_AD = "CORRECT_AD",
  PUBLISH_AD = "PUBLISH_AD",
  PUBLISHED_NOW = "PUBLISHED_NOW",
  PUBLISHED_LATER = "PUBLISHED_LATER",
  PROGRAMME_PUBLISHED_LATER = "PROGRAMME_PUBLISHED_LATER",
  REFRESH_AD = "REFRESH_AD",
  REFRESH_ADS = "REFRESH_ADS",
  CLOSE_AD = "CLOSE_AD",
  CLOSE_ADS = "CLOSE_ADS",
  COMMENT_AD = "COMMENT_AD",
  EDITED_AD = "EDITED_AD",
  REFRESHED_AD = "REFRESHED_AD",
  CLOSED_AD = "CLOSED_AD",
  DELETE_AD = "DELETE_AD",
}

const useMultiDiffusionAdStore = create<MultiDiffusionAdState>((set) => ({
  chosenAd: null,
  setChosenAd: (ad: AdModel | null) => set({ chosenAd: ad }),
  manageMultiDiffusionAdSelected: null,
  setManageMultiDiffusionAdSelected: (ad: MultiDiffusionAdModel | null) => set({ manageMultiDiffusionAdSelected: ad }),
  modalTypeOpened: null,
  setModalTypeOpened: (modalType: MultiDiffusionModalEnum | null) => set({ modalTypeOpened: modalType }),
  loadingPublish: false,
  setLoadingPublish: (loading: boolean) => set({ loadingPublish: loading }),
}));

export default useMultiDiffusionAdStore;
