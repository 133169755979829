import ModalComponent from "@components/modal/Modal.component";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import AIOButtonComponent from "@components/Button.component";
import { useTranslation } from "react-i18next";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { Dispatch, SetStateAction, useState } from "react";
import { FieldValidationType, getFieldError } from "@utils/yup.utils";
import { adDetailsService } from "@store/ads/details";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

interface EditCustomNameModalProps {
  adId?: string;
  applyInfoId: string | number;
  handleCloseModal: () => void;
  handleChangeName: Dispatch<SetStateAction<string | undefined>>;
  fieldName: string;
}

const EditCustomNameModal = (props: EditCustomNameModalProps) => {
  const { adId, applyInfoId, handleCloseModal, fieldName, handleChangeName } = props;
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [newName, setNewName] = useState(fieldName ? fieldName : "");
  const [loading, setLoading] = useState(false);

  const handleNameChange = (value: string) => {
    setNewName(value);
  };

  const handleConfirm = () => {
    if (errors.name || !adId) return;
    setLoading(true);
    adDetailsService.editCustomName(adId, applyInfoId, newName).pipe(finalize(() => setLoading(false)))
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => {
          enqueueSnackbar(t("ads.details.applyTab.success.cvName"), { variant: "success" });
          handleChangeName(newName);
          handleCloseModal();
        }
      });
  };

  const errors = {
    name: getFieldError(newName, FieldValidationType.REQUIRED_STRING)
  };

  return (
    <ModalComponent
      handleClose={handleCloseModal}
      title={t("applications.editName")}
      titleLeft
      content={
        <Stack spacing={3} py="30px" width={breakpointDownSM ? "100%" : "300px"}>
          <AIOTextfieldComponent
            title={t("applications.editName")}
            placeholder={t("applications.editName")}
            onChange={handleNameChange}
            value={newName}
            error={errors.name}
          />
        </Stack>
      }
      maxWidth={false}
      fullWidth={false}
      paddingButton="10px 0px 40px 0px"
      actions={(
        <AIOButtonComponent
          title={t("global.confirm")}
          variant="contained"
          ariaLabel="modal"
          onClick={handleConfirm}
          disabled={loading} />
      )}
    />
  );
};

export default EditCustomNameModal;
