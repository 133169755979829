import { Stack, Switch, Typography, styled, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { ManageEntityParametersModel } from "@store/entities/details";
import { AltaysAutomationEnum } from "@store/common/altaysAutomation.enum";

import { Colors } from "@constants/colors.constant";

export const RadioBtn = styled(Radio)({
  width: "15px",
  height: "15px",
  alignSelf: "center",
  marginRight: "5px",
  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
    color: Colors.primary,
    width: "15px",
    height: "15px",
  },
  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
    color: Colors.primary,
    width: "15px",
    height: "15px",
  },
});

interface AccessToAltaysConfigComponentProps {
  isActivated?: boolean;
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
  altaysAutomation?: AltaysAutomationEnum;
}

const AccessToAltaysConfigComponent = (props: AccessToAltaysConfigComponentProps) => {
  const { isActivated = false, updateParameters, altaysAutomation } = props;

  const { t } = useTranslation();

  const handleChangeAltaysAutomation = (value: AltaysAutomationEnum) => {
    updateParameters((state) => ({...state, altaysAutomation: value}));
  };

  return (
    <Stack position="relative" width="100%">
      <Stack flex={0.5} width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("clients.details.parametersTab.accessToAltays.accessToAltays")}
          </Typography>
          <Switch 
            checked={isActivated}
            onChange={(_, checked) => 
              updateParameters((state) => ({...state, hasAccessToAltays: checked}))
            }
          />
        </Stack>
        {isActivated && (
          <Stack spacing={1}>
            <FormControlLabel 
              value={AltaysAutomationEnum.NO_AUTOMATION} 
              control={
                <RadioBtn 
                  id="noAutomation"
                  value={AltaysAutomationEnum.NO_AUTOMATION}
                  checked={altaysAutomation === AltaysAutomationEnum.NO_AUTOMATION} 
                  onChange={(_, checked) => handleChangeAltaysAutomation(checked ? AltaysAutomationEnum.NO_AUTOMATION : AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_CREATION)}
                />
              }
              label={
                <Typography fontSize={14} fontWeight="300">
                  {t("clients.details.parametersTab.accessToAltays.noAutomation")}
                </Typography>
              }
            />
            <FormControlLabel 
              value={AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_CREATION} 
              control={
                <RadioBtn 
                  id="automationOnApplicationCreation" 
                  value={AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_CREATION}
                  checked={altaysAutomation === AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_CREATION} 
                  onChange={(_, checked) => handleChangeAltaysAutomation(checked ? AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_CREATION : AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_AI_EVALUATION)}
                />
              }
              label={
                <Typography fontSize={14} fontWeight="300">
                  {t("clients.details.parametersTab.accessToAltays.automationOnApplicationCreation")}
                </Typography>
              }
            />
            <FormControlLabel 
              value={AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_AI_EVALUATION} 
              control={
                <RadioBtn 
                  id="automationOnApplicationAiEvaluation" 
                  value={AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_AI_EVALUATION}
                  checked={altaysAutomation === AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_AI_EVALUATION} 
                  onChange={(_, checked) => handleChangeAltaysAutomation(checked ? AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_AI_EVALUATION : AltaysAutomationEnum.AUTOMATION_ON_APPLICATION_CREATION)}
                />
              }
              label={
                <Typography fontSize={14} fontWeight="300" whiteSpace="nowrap">
                  {t("clients.details.parametersTab.accessToAltays.automationOnApplicationAiEvaluation")}
                </Typography>
              }
            />
          </Stack>
        )}
      </Stack>      
    </Stack>
  );
};

export default AccessToAltaysConfigComponent;
