import { SelectItem } from "@components/input/Select.component";
import { AdModel, AdStats, MultiDiffusionSalaryTypeEnum } from "@store/ads";
import { Application, ApplicationsListEnum } from "@store/applications";
import { Action } from "@store/common/actions.model";
import { Comment } from "@store/common/comments.model";
import { AppCountryEnum, getAppCountry } from "@store/common/country.model";
import { AnnouncementAffiliateModel } from "@store/entities/afiliates";
import { JobBoard } from "@store/jobBoards";
import { PaginatedMetaData } from "@utils/infinitescroll.utils";

import I18nUtils from "@utils/i18n.utils";

export enum ContractTypeEnum {
  /* COMMON */
  INTERNSHIP = "INTERNSHIP",
  /* ------ */

  /* FR */
  CDI = "CDI",
  CDD = "CDD",
  INTERIM = "INTERIM",
  APPRENTICESHIP = "APPRENTICESHIP",
  PROFESSIONALIZATION_CONTRACT = "PROFESSIONALIZATION_CONTRACT",
  ALTERNATION_CONTRACT = "ALTERNATION_CONTRACT",
  SEASONAL = "SEASONAL",
  /* -- */

  /* US */
  AT_WILL_EMPLOYMENT = "AT_WILL_EMPLOYMENT",
  FIXED_TERM_CONTRACT = "FIXED_TERM_CONTRACT",
  TEMPORARY_EMPLOYMENT_CONTRACT = "TEMPORARY_EMPLOYMENT_CONTRACT",
  INDEPENDENT_CONTRACTOR_AGREEMENT = "INDEPENDENT_CONTRACTOR_AGREEMENT",
  PART_TIME_EMPLOYMENT_CONTRACT = "PART_TIME_EMPLOYMENT_CONTRACT",
  SEASONAL_EMPLOYMENT_CONTRACT = "SEASONAL_EMPLOYMENT_CONTRACT",
  FREELANCE = "FREELANCE",
  FULL_TIME_EMPLOYMENT_CONTRACT = "FULL_TIME_EMPLOYMENT_CONTRACT",
  /* -- */
}

export namespace ContractTypeFunctions {
  export const label = (contractType: ContractTypeEnum) => {
    switch (contractType) {
      // COMMON
      case ContractTypeEnum.INTERNSHIP:
        return I18nUtils.t("ads.contractType.internship");

      // FR
      case ContractTypeEnum.CDI:
        return I18nUtils.t("ads.contractType.cdi");
      case ContractTypeEnum.CDD:
        return I18nUtils.t("ads.contractType.cdd");
      case ContractTypeEnum.INTERIM:
        return I18nUtils.t("ads.contractType.interim");
      case ContractTypeEnum.APPRENTICESHIP:
        return I18nUtils.t("ads.contractType.apprenticeship");
      case ContractTypeEnum.PROFESSIONALIZATION_CONTRACT:
        return I18nUtils.t("ads.contractType.professionalizationContract");
      case ContractTypeEnum.ALTERNATION_CONTRACT:
        return I18nUtils.t("ads.contractType.alternationContract");
      case ContractTypeEnum.SEASONAL:
        return I18nUtils.t("ads.contractType.seasonal");

      // US
      case ContractTypeEnum.AT_WILL_EMPLOYMENT:
        return I18nUtils.t("ads.contractType.atWillEmployment");
      case ContractTypeEnum.FIXED_TERM_CONTRACT:
        return I18nUtils.t("ads.contractType.fixedTermContract");
      case ContractTypeEnum.TEMPORARY_EMPLOYMENT_CONTRACT:
        return I18nUtils.t("ads.contractType.temporaryEmploymentContract");
      case ContractTypeEnum.INDEPENDENT_CONTRACTOR_AGREEMENT:
        return I18nUtils.t("ads.contractType.independentContractorAgreement");
      case ContractTypeEnum.PART_TIME_EMPLOYMENT_CONTRACT:
        return I18nUtils.t("ads.contractType.partTimeEmploymentContract");
      case ContractTypeEnum.SEASONAL_EMPLOYMENT_CONTRACT:
        return I18nUtils.t("ads.contractType.seasonalEmploymentContract");
      case ContractTypeEnum.FREELANCE:
        return I18nUtils.t("ads.contractType.freelance");
      case ContractTypeEnum.FULL_TIME_EMPLOYMENT_CONTRACT:
        return I18nUtils.t("ads.contractType.fullTimeEmploymentContract");
      default:
        return "";
    }
  };

  export const selectItems: SelectItem[] = [
    // COMMON
    { label: ContractTypeFunctions.label(ContractTypeEnum.INTERNSHIP), value: ContractTypeEnum.INTERNSHIP },

    // FR
    { label: ContractTypeFunctions.label(ContractTypeEnum.CDI), value: ContractTypeEnum.CDI, data: AppCountryEnum.FR },
    { label: ContractTypeFunctions.label(ContractTypeEnum.CDD), value: ContractTypeEnum.CDD, data: AppCountryEnum.FR },
    { label: ContractTypeFunctions.label(ContractTypeEnum.INTERIM), value: ContractTypeEnum.INTERIM, data: AppCountryEnum.FR },
    { label: ContractTypeFunctions.label(ContractTypeEnum.APPRENTICESHIP), value: ContractTypeEnum.APPRENTICESHIP, data: AppCountryEnum.FR },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.PROFESSIONALIZATION_CONTRACT),
      value: ContractTypeEnum.PROFESSIONALIZATION_CONTRACT,
      data: AppCountryEnum.FR,
    },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.ALTERNATION_CONTRACT),
      value: ContractTypeEnum.ALTERNATION_CONTRACT,
      data: AppCountryEnum.FR,
    },
    { label: ContractTypeFunctions.label(ContractTypeEnum.SEASONAL), value: ContractTypeEnum.SEASONAL, data: AppCountryEnum.FR },

    // US
    { label: ContractTypeFunctions.label(ContractTypeEnum.AT_WILL_EMPLOYMENT), value: ContractTypeEnum.AT_WILL_EMPLOYMENT, data: AppCountryEnum.US },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.FIXED_TERM_CONTRACT),
      value: ContractTypeEnum.FIXED_TERM_CONTRACT,
      data: AppCountryEnum.US,
    },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.TEMPORARY_EMPLOYMENT_CONTRACT),
      value: ContractTypeEnum.TEMPORARY_EMPLOYMENT_CONTRACT,
      data: AppCountryEnum.US,
    },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.INDEPENDENT_CONTRACTOR_AGREEMENT),
      value: ContractTypeEnum.INDEPENDENT_CONTRACTOR_AGREEMENT,
      data: AppCountryEnum.US,
    },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.PART_TIME_EMPLOYMENT_CONTRACT),
      value: ContractTypeEnum.PART_TIME_EMPLOYMENT_CONTRACT,
      data: AppCountryEnum.US,
    },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.SEASONAL_EMPLOYMENT_CONTRACT),
      value: ContractTypeEnum.SEASONAL_EMPLOYMENT_CONTRACT,
      data: AppCountryEnum.US,
    },
    { label: ContractTypeFunctions.label(ContractTypeEnum.FREELANCE), value: ContractTypeEnum.FREELANCE, data: AppCountryEnum.US },
    {
      label: ContractTypeFunctions.label(ContractTypeEnum.FULL_TIME_EMPLOYMENT_CONTRACT),
      value: ContractTypeEnum.FULL_TIME_EMPLOYMENT_CONTRACT,
      data: AppCountryEnum.US,
    },
  ].filter((s) => !s.data || s.data === getAppCountry());
}

export enum DomainEnum {
  SANTE_ET_MEDECINE = "SANTE_ET_MEDECINE",
  TECHNOLOGIE_DE_LINFORMATION_ET_INFORMATIQUE = "TECHNOLOGIE_DE_LINFORMATION_ET_INFORMATIQUE",
  FINANCE_ET_COMPTABILITE = "FINANCE_ET_COMPTABILITE",
  ENSEIGNEMENT_ET_EDUCATION = "ENSEIGNEMENT_ET_EDUCATION",
  VENTE_ET_MARKETING = "VENTE_ET_MARKETING",
  INGENIERIE = "INGENIERIE",
  GESTION_ET_ADMINISTRATION = "GESTION_ET_ADMINISTRATION",
  RESSOURCES_HUMAINES = "RESSOURCES_HUMAINES",
  DROIT_ET_JUSTICE = "DROIT_ET_JUSTICE",
  ARTS_ET_DIVERTISSEMENT = "ARTS_ET_DIVERTISSEMENT",
  COMMUNICATION_ET_RELATIONS_PUBLIQUES = "COMMUNICATION_ET_RELATIONS_PUBLIQUES",
  SCIENCES_ET_RECHERCHE = "SCIENCES_ET_RECHERCHE",
  SERVICE_A_LA_CLIENTELE = "SERVICE_A_LA_CLIENTELE",
  HOTELLERIE_ET_RESTAURATION = "HOTELLERIE_ET_RESTAURATION",
  TRANSPORT_ET_LOGISTIQUE = "TRANSPORT_ET_LOGISTIQUE",
  MEDIAS_ET_JOURNALISME = "MEDIAS_ET_JOURNALISME",
  DESIGN_ET_CREATION = "DESIGN_ET_CREATION",
  CONSTRUCTION_ET_ARCHITECTURE = "CONSTRUCTION_ET_ARCHITECTURE",
  AGRICULTURE_ET_ENVIRONNEMENT = "AGRICULTURE_ET_ENVIRONNEMENT",
  SERVICES_SOCIAUX_ET_COMMUNAUTAIRES = "SERVICES_SOCIAUX_ET_COMMUNAUTAIRES",
  SECTEUR_PUBLIC_ET_ADMINISTRATION_GOUVERNEMENTALE = "SECTEUR_PUBLIC_ET_ADMINISTRATION_GOUVERNEMENTALE",
  VENTE_AU_DETAIL_ET_COMMERCE = "VENTE_AU_DETAIL_ET_COMMERCE",
  SPORT_ET_LOISIRS = "SPORT_ET_LOISIRS",
  FABRICATION_ET_PRODUCTION = "FABRICATION_ET_PRODUCTION",
  CONSULTATION_ET_CONSEIL = "CONSULTATION_ET_CONSEIL",
  AUTRES = "AUTRES",
}

export namespace DomainFunctions {
  export const label = (domain: DomainEnum) => {
    switch (domain) {
      case DomainEnum.SANTE_ET_MEDECINE:
        return I18nUtils.t("ads.domain.healthAndMedicine");
      case DomainEnum.TECHNOLOGIE_DE_LINFORMATION_ET_INFORMATIQUE:
        return I18nUtils.t("ads.domain.informationTechnologyAndComputerScience");
      case DomainEnum.FINANCE_ET_COMPTABILITE:
        return I18nUtils.t("ads.domain.financeAndAccounting");
      case DomainEnum.ENSEIGNEMENT_ET_EDUCATION:
        return I18nUtils.t("ads.domain.teachingAndEducation");
      case DomainEnum.VENTE_ET_MARKETING:
        return I18nUtils.t("ads.domain.salesAndMarketing");
      case DomainEnum.INGENIERIE:
        return I18nUtils.t("ads.domain.engineering");
      case DomainEnum.GESTION_ET_ADMINISTRATION:
        return I18nUtils.t("ads.domain.managementAndAdministration");
      case DomainEnum.RESSOURCES_HUMAINES:
        return I18nUtils.t("ads.domain.humanResources");
      case DomainEnum.DROIT_ET_JUSTICE:
        return I18nUtils.t("ads.domain.lawAndJustice");
      case DomainEnum.ARTS_ET_DIVERTISSEMENT:
        return I18nUtils.t("ads.domain.artsAndEntertainment");
      case DomainEnum.COMMUNICATION_ET_RELATIONS_PUBLIQUES:
        return I18nUtils.t("ads.domain.communicationAndPublicRelations");
      case DomainEnum.SCIENCES_ET_RECHERCHE:
        return I18nUtils.t("ads.domain.science");
      case DomainEnum.SERVICE_A_LA_CLIENTELE:
        return I18nUtils.t("ads.domain.customerService");
      case DomainEnum.HOTELLERIE_ET_RESTAURATION:
        return I18nUtils.t("ads.domain.hospitalityAndCatering");
      case DomainEnum.TRANSPORT_ET_LOGISTIQUE:
        return I18nUtils.t("ads.domain.transportationAndLogistics");
      case DomainEnum.MEDIAS_ET_JOURNALISME:
        return I18nUtils.t("ads.domain.mediaAndJournalism");
      case DomainEnum.DESIGN_ET_CREATION:
        return I18nUtils.t("ads.domain.designAndCreative");
      case DomainEnum.CONSTRUCTION_ET_ARCHITECTURE:
        return I18nUtils.t("ads.domain.constructionAndArchitecture");
      case DomainEnum.AGRICULTURE_ET_ENVIRONNEMENT:
        return I18nUtils.t("ads.domain.agricultureAndEnvironmentalServices");
      case DomainEnum.SERVICES_SOCIAUX_ET_COMMUNAUTAIRES:
        return I18nUtils.t("ads.domain.socialAndCommunityServices");
      case DomainEnum.SECTEUR_PUBLIC_ET_ADMINISTRATION_GOUVERNEMENTALE:
        return I18nUtils.t("ads.domain.publicSectorAndGovernmentAdministration");
      case DomainEnum.VENTE_AU_DETAIL_ET_COMMERCE:
        return I18nUtils.t("ads.domain.retailAndTrade");
      case DomainEnum.SPORT_ET_LOISIRS:
        return I18nUtils.t("ads.domain.sportsAndRecreation");
      case DomainEnum.FABRICATION_ET_PRODUCTION:
        return I18nUtils.t("ads.domain.manufacturingAndProduction");
      case DomainEnum.CONSULTATION_ET_CONSEIL:
        return I18nUtils.t("ads.domain.consultingAndAdvisory");
      case DomainEnum.AUTRES:
        return I18nUtils.t("ads.domain.other");
    }
  };

  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("ads.domain.healthAndMedicine"), value: DomainEnum.SANTE_ET_MEDECINE },
    {
      label: I18nUtils.t("ads.domain.informationTechnologyAndComputerScience"),
      value: DomainEnum.TECHNOLOGIE_DE_LINFORMATION_ET_INFORMATIQUE,
    },
    { label: I18nUtils.t("ads.domain.financeAndAccounting"), value: DomainEnum.FINANCE_ET_COMPTABILITE },
    { label: I18nUtils.t("ads.domain.teachingAndEducation"), value: DomainEnum.ENSEIGNEMENT_ET_EDUCATION },
    { label: I18nUtils.t("ads.domain.salesAndMarketing"), value: DomainEnum.VENTE_ET_MARKETING },
    { label: I18nUtils.t("ads.domain.engineering"), value: DomainEnum.INGENIERIE },
    {
      label: I18nUtils.t("ads.domain.managementAndAdministration"),
      value: DomainEnum.GESTION_ET_ADMINISTRATION,
    },
    { label: I18nUtils.t("ads.domain.humanResources"), value: DomainEnum.RESSOURCES_HUMAINES },
    { label: I18nUtils.t("ads.domain.lawAndJustice"), value: DomainEnum.DROIT_ET_JUSTICE },
    {
      label: I18nUtils.t("ads.domain.artsAndEntertainment"),
      value: DomainEnum.ARTS_ET_DIVERTISSEMENT,
    },
    {
      label: I18nUtils.t("ads.domain.communicationAndPublicRelations"),
      value: DomainEnum.COMMUNICATION_ET_RELATIONS_PUBLIQUES,
    },
    { label: I18nUtils.t("ads.domain.science"), value: DomainEnum.SCIENCES_ET_RECHERCHE },
    { label: I18nUtils.t("ads.domain.customerService"), value: DomainEnum.SERVICE_A_LA_CLIENTELE },
    {
      label: I18nUtils.t("ads.domain.hospitalityAndCatering"),
      value: DomainEnum.HOTELLERIE_ET_RESTAURATION,
    },
    {
      label: I18nUtils.t("ads.domain.transportationAndLogistics"),
      value: DomainEnum.TRANSPORT_ET_LOGISTIQUE,
    },
    { label: I18nUtils.t("ads.domain.mediaAndJournalism"), value: DomainEnum.MEDIAS_ET_JOURNALISME },
    { label: I18nUtils.t("ads.domain.designAndCreative"), value: DomainEnum.DESIGN_ET_CREATION },
    {
      label: I18nUtils.t("ads.domain.constructionAndArchitecture"),
      value: DomainEnum.CONSTRUCTION_ET_ARCHITECTURE,
    },
    {
      label: I18nUtils.t("ads.domain.agricultureAndEnvironmentalServices"),
      value: DomainEnum.AGRICULTURE_ET_ENVIRONNEMENT,
    },
    {
      label: I18nUtils.t("ads.domain.socialAndCommunityServices"),
      value: DomainEnum.SERVICES_SOCIAUX_ET_COMMUNAUTAIRES,
    },
    {
      label: I18nUtils.t("ads.domain.publicSectorAndGovernmentAdministration"),
      value: DomainEnum.SECTEUR_PUBLIC_ET_ADMINISTRATION_GOUVERNEMENTALE,
    },
    { label: I18nUtils.t("ads.domain.retailAndTrade"), value: DomainEnum.VENTE_AU_DETAIL_ET_COMMERCE },
    { label: I18nUtils.t("ads.domain.sportsAndRecreation"), value: DomainEnum.SPORT_ET_LOISIRS },
    {
      label: I18nUtils.t("ads.domain.manufacturingAndProduction"),
      value: DomainEnum.FABRICATION_ET_PRODUCTION,
    },
    { label: I18nUtils.t("ads.domain.consultingAndAdvisory"), value: DomainEnum.CONSULTATION_ET_CONSEIL },
    { label: I18nUtils.t("ads.domain.other"), value: DomainEnum.AUTRES },
  ];
}

export enum AdStatusEnum {
  ETAM = "ETAM",
  CADRE = "Cadre",
  STAGE = "Stage",
  ALTERNANCE = "Alternance",
  APPRENTI = "Apprenti",
}

export namespace AdStatusFunctions {
  export const label = (status: AdStatusEnum) => {
    switch (status) {
      case AdStatusEnum.ETAM:
        return I18nUtils.t("ads.status.etam");
      case AdStatusEnum.CADRE:
        return I18nUtils.t("ads.status.cadre");
      case AdStatusEnum.STAGE:
        return I18nUtils.t("ads.status.stage");
      case AdStatusEnum.ALTERNANCE:
        return I18nUtils.t("ads.status.alternance");
      case AdStatusEnum.APPRENTI:
        return I18nUtils.t("ads.status.apprenti");
    }
  };

  export const selectItems: SelectItem[] = [
    { label: AdStatusFunctions.label(AdStatusEnum.ETAM), value: AdStatusEnum.ETAM, data: AppCountryEnum.FR },
    { label: AdStatusFunctions.label(AdStatusEnum.CADRE), value: AdStatusEnum.CADRE },
    { label: AdStatusFunctions.label(AdStatusEnum.STAGE), value: AdStatusEnum.STAGE },
    { label: AdStatusFunctions.label(AdStatusEnum.ALTERNANCE), value: AdStatusEnum.ALTERNANCE },
    { label: AdStatusFunctions.label(AdStatusEnum.APPRENTI), value: AdStatusEnum.APPRENTI },
  ].filter((s) => !s.data || s.data === getAppCountry());
}

export enum CodeTypeExpProEnum {
  E = "E",
  D = "D",
}

export namespace CodeTypeExpProFunctions {
  export const label = (codeTypeExpPro: CodeTypeExpProEnum) => {
    switch (codeTypeExpPro) {
      case CodeTypeExpProEnum.E:
        return I18nUtils.t("ads.codeTypeExpPro.E");
      case CodeTypeExpProEnum.D:
        return I18nUtils.t("ads.codeTypeExpPro.D");
    }
  };

  export const selectItems: SelectItem[] = [
    { label: CodeTypeExpProFunctions.label(CodeTypeExpProEnum.E), value: CodeTypeExpProEnum.E },
    { label: CodeTypeExpProFunctions.label(CodeTypeExpProEnum.D), value: CodeTypeExpProEnum.D },
  ];
}

export enum TypeDureeExpProEnum {
  MO = "MO",
  AN = "AN",
}

export namespace TypeDureeExpProFunctions {
  export const label = (typeDureeExpPro: TypeDureeExpProEnum) => {
    switch (typeDureeExpPro) {
      case TypeDureeExpProEnum.MO:
        return I18nUtils.t("ads.typeDureeExpPro.MO");
      case TypeDureeExpProEnum.AN:
        return I18nUtils.t("ads.typeDureeExpPro.AN");
    }
  };

  export const selectItems: SelectItem[] = [
    { label: TypeDureeExpProFunctions.label(TypeDureeExpProEnum.MO), value: TypeDureeExpProEnum.MO },
    { label: TypeDureeExpProFunctions.label(TypeDureeExpProEnum.AN), value: TypeDureeExpProEnum.AN },
  ];
}

export enum HorairesTypeEnum {
  IRR = "IRR",
  NOR = "NOR",
  VAR = "VAR",
  HAN = "HAN",
  TLS = "TLS",
  TLD = "TLD",
  TSD = "TSD",
  TDN = "TDN",
  T28 = "T28",
}

export namespace HorairesTypeFunctions {
  export const label = (horairesType: HorairesTypeEnum) => {
    switch (horairesType) {
      case HorairesTypeEnum.IRR:
        return I18nUtils.t("ads.horairesType.IRR");
      case HorairesTypeEnum.NOR:
        return I18nUtils.t("ads.horairesType.NOR");
      case HorairesTypeEnum.VAR:
        return I18nUtils.t("ads.horairesType.VAR");
      case HorairesTypeEnum.HAN:
        return I18nUtils.t("ads.horairesType.HAN");
      case HorairesTypeEnum.TLS:
        return I18nUtils.t("ads.horairesType.TLS");
      case HorairesTypeEnum.TLD:
        return I18nUtils.t("ads.horairesType.TLD");
      case HorairesTypeEnum.TSD:
        return I18nUtils.t("ads.horairesType.TSD");
      case HorairesTypeEnum.TDN:
        return I18nUtils.t("ads.horairesType.TDN");
      case HorairesTypeEnum.T28:
        return I18nUtils.t("ads.horairesType.T28");
    }
  };

  export const selectItems: SelectItem[] = [
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.IRR), value: HorairesTypeEnum.IRR },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.NOR), value: HorairesTypeEnum.NOR },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.VAR), value: HorairesTypeEnum.VAR },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.HAN), value: HorairesTypeEnum.HAN },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.TLS), value: HorairesTypeEnum.TLS },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.TLD), value: HorairesTypeEnum.TLD },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.TSD), value: HorairesTypeEnum.TSD },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.TDN), value: HorairesTypeEnum.TDN },
    { label: HorairesTypeFunctions.label(HorairesTypeEnum.T28), value: HorairesTypeEnum.T28 },
  ];
}

export namespace SalaryTypeFunctions {
  export const label = (salaryType: MultiDiffusionSalaryTypeEnum) => {
    switch (salaryType) {
      case MultiDiffusionSalaryTypeEnum.M12:
        return I18nUtils.t("ads.salaryType.M12");
      case MultiDiffusionSalaryTypeEnum.M13:
        return I18nUtils.t("ads.salaryType.M13");
      case MultiDiffusionSalaryTypeEnum.A12:
        return I18nUtils.t("ads.salaryType.A12");
      case MultiDiffusionSalaryTypeEnum.A13:
        return I18nUtils.t("ads.salaryType.A13");
    }
  };

  export const selectItems: SelectItem[] = [
    { label: SalaryTypeFunctions.label(MultiDiffusionSalaryTypeEnum.M12), value: MultiDiffusionSalaryTypeEnum.M12 },
    { label: SalaryTypeFunctions.label(MultiDiffusionSalaryTypeEnum.M13), value: MultiDiffusionSalaryTypeEnum.M13 },
    { label: SalaryTypeFunctions.label(MultiDiffusionSalaryTypeEnum.A12), value: MultiDiffusionSalaryTypeEnum.A12 },
    { label: SalaryTypeFunctions.label(MultiDiffusionSalaryTypeEnum.A13), value: MultiDiffusionSalaryTypeEnum.A13 },
  ];
}
export enum ConditionsExerciseEnum {
  AUT = "AUT",
  DOM = "DOM",
  TDT = "TDT",
  TLT = "TLT",
  EBR = "EBR",
  EIN = "EIN",
  GHA = "GHA",
  EEC = "EEC",
  EXT = "EXT",
  PCL = "PCL",
  SPE = "SPE",
  SDP = "SDP",
  TRE = "TRE",
  SAP = "SAP",
}

export namespace ConditionsExerciseFunctions {
  export const label = (conditionsExercise: ConditionsExerciseEnum) => {
    switch (conditionsExercise) {
      case ConditionsExerciseEnum.AUT:
        return I18nUtils.t("ads.conditionsExercise.AUT");
      case ConditionsExerciseEnum.DOM:
        return I18nUtils.t("ads.conditionsExercise.DOM");
      case ConditionsExerciseEnum.TDT:
        return I18nUtils.t("ads.conditionsExercise.TDT");
      case ConditionsExerciseEnum.TLT:
        return I18nUtils.t("ads.conditionsExercise.TLT");
      case ConditionsExerciseEnum.EBR:
        return I18nUtils.t("ads.conditionsExercise.EBR");
      case ConditionsExerciseEnum.EIN:
        return I18nUtils.t("ads.conditionsExercise.EIN");
      case ConditionsExerciseEnum.GHA:
        return I18nUtils.t("ads.conditionsExercise.GHA");
      case ConditionsExerciseEnum.EEC:
        return I18nUtils.t("ads.conditionsExercise.EEC");
      case ConditionsExerciseEnum.EXT:
        return I18nUtils.t("ads.conditionsExercise.EXT");
      case ConditionsExerciseEnum.PCL:
        return I18nUtils.t("ads.conditionsExercise.PCL");
      case ConditionsExerciseEnum.SPE:
        return I18nUtils.t("ads.conditionsExercise.SPE");
      case ConditionsExerciseEnum.SDP:
        return I18nUtils.t("ads.conditionsExercise.SDP");
      case ConditionsExerciseEnum.TRE:
        return I18nUtils.t("ads.conditionsExercise.TRE");
      case ConditionsExerciseEnum.SAP:
        return I18nUtils.t("ads.conditionsExercise.SAP");
    }
  };

  export const selectItems: SelectItem[] = [
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.AUT), value: ConditionsExerciseEnum.AUT },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.DOM), value: ConditionsExerciseEnum.DOM },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.TDT), value: ConditionsExerciseEnum.TDT },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.TLT), value: ConditionsExerciseEnum.TLT },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.EBR), value: ConditionsExerciseEnum.EBR },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.EIN), value: ConditionsExerciseEnum.EIN },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.GHA), value: ConditionsExerciseEnum.GHA },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.EEC), value: ConditionsExerciseEnum.EEC },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.EXT), value: ConditionsExerciseEnum.EXT },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.PCL), value: ConditionsExerciseEnum.PCL },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.SPE), value: ConditionsExerciseEnum.SPE },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.SDP), value: ConditionsExerciseEnum.SDP },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.TRE), value: ConditionsExerciseEnum.TRE },
    { label: ConditionsExerciseFunctions.label(ConditionsExerciseEnum.SAP), value: ConditionsExerciseEnum.SAP },
  ];
}
export interface PotentialProfile {
  id: string;
  profileName: string;
  profileDescription: string;
  profileURL: string;
  profilePictureURL?: string;
}

export interface AdDetailsApplicationsList {
  name: ApplicationsListEnum;
  applications: Application[];
}

interface AdApplicationsStatsMonthSource {
  references?: string[];
  applicationNbr: number;
}

interface AdApplicationsStatsMonth {
  sources: { [source: string]: AdApplicationsStatsMonthSource };
  onlineAnnouncements?: number;
}

export interface AdApplicationsStats {
  [month: string]: AdApplicationsStatsMonth;
}

export interface ManageAdDetailsModel extends Partial<Omit<AdDetailsDetailsModel, "jobBoards" | "contractType" | "domain" | "affiliate" | "status">> {
  affiliate?: SelectItem;

  jobBoards?: SelectItem[];
  criterias?: string[];

  contractType?: SelectItem;
  domain?: SelectItem;

  status?: SelectItem;
}

export const adToManageAd = (ad: AdDetailsDetailsModel, selectedAffiliate?: SelectItem) => ({
  ...(ad ?? {}),
  contractType: ad?.contractType ? ContractTypeFunctions.selectItems.find((ct) => ct.value === ad.contractType) : undefined,
  domain: ad?.domain ? DomainFunctions.selectItems.find((d) => d.value === ad.domain) : undefined,
  affiliate: selectedAffiliate,
  status: ad?.status ? AdStatusFunctions.selectItems.find((s) => s.value === ad.status) : undefined,
});

export interface AdDetailsDetailsModel extends Omit<AdModel, "affiliate" | "domain" | "contractType"> {
  affiliate: AnnouncementAffiliateModel;

  jobBoards: JobBoard[];
  slogan?: string;
  url?: string;

  isCustom: boolean;
  lastSeen: Date;
  department: string;
  region: string;
  companyInformation?: string;
  description: string;
  profile?: string;
  advantages?: string;
  minSalary: number;
  maxSalary: number;
  contractType: string;
  contractDuration?: string;
  workingTime?: string;
  domain?: DomainEnum;
  criterias?: string[];
  status: AdStatusEnum;
  archivedAt?: Date | undefined;
  hasAccessToAltays?: boolean;
}

export interface AdDetailsModel {
  details?: AdDetailsDetailsModel;
  stats?: AdStats;
  applications?: AdDetailsApplicationsList[];
  slogans?: string[];
  tags?: string[];
  potentialProfiles?: PotentialProfile[];
  comments?: Comment[];
  actions?: Action[];
  actionsPaginatedMeta?: PaginatedMetaData;
}
