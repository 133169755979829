import { Stack } from "@mui/material";
import { StyledTextDescribeModal, StyledTitleModal } from "@components/modal/publishAds/ConfirmPublishAds.modal";
import { useTranslation } from "react-i18next";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { FranceTravailAnnulationMotifEnum } from "@store/multiDiffusionAd/multiDiffusionAd.model";
import { SelectItem } from "@components/input/Select.component";

interface CloseAdModalProps {
  selectedMotif: SelectItem | undefined;
  setSelectedMotif: (motif: SelectItem | undefined) => void;
}

const CloseAdModal = ({ selectedMotif, setSelectedMotif }: CloseAdModalProps) => {
  const { t } = useTranslation();

  const motifs = Object.values(FranceTravailAnnulationMotifEnum).map((motif) => ({
    label: t(`multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.motifs.${motif}`),
    value: motif,
  }));

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mb={3} mt={2}>
      <img src="/images/icon_closeAd_primary.svg" alt="check" style={{ width: "56px", height: "47px", marginBottom: "1rem" }} />
      <StyledTitleModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.title")}</StyledTitleModal>
      <StyledTextDescribeModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.description")}</StyledTextDescribeModal>
      <Stack direction="column" spacing={1} width="100%" minWidth="200px">
        <SelectWithSearchComponent
          items={motifs}
          placeholder={t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.selectMotif")}
          handleChange={setSelectedMotif}
          value={selectedMotif}
        />
      </Stack>
    </Stack>
  );
};

export default CloseAdModal;
