import { MenuItem, Select, SxProps, Theme } from "@mui/material";

export interface MultiSelectComponentItem {
  label: string;
  value: any;
}

interface MultiSelectComponentProps {
  color?: "primary" | "secondary";
  label?: string;
  handleChange?: (value: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  multiSelectedLabel?: (count: number) => string;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  values?: any[];
  items?: MultiSelectComponentItem[];
  errored?: boolean
  errorStyle?: SxProps<Theme>
  sx?: SxProps<Theme>
}

const MultiSelectComponent = (props: MultiSelectComponentProps) => {
  const { fullWidth = true, handleChange, required, disabled, multiSelectedLabel, placeholder, readOnly, items, values = [], errored = false, errorStyle, sx } = props;

  return (
    <Select
      multiple
      onChange={(evt) => handleChange?.(evt.target.value)}
      size="small"
      variant="standard"
      disableUnderline
      displayEmpty
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      value={values}
      renderValue={(selected) => {
        if (!selected.length || !items) return placeholder;
        if (selected.length === 1) return items.find((it) => it.value === selected[0])?.label;
        if (!!multiSelectedLabel) return multiSelectedLabel(values.length);
        return selected.map((s) => items.find((it) => it.value === s)?.label).join(", ");
      }}
      sx={{ ...(errored ? errorStyle : sx) }}
    >
      {placeholder && (
        <MenuItem onClick={() => handleChange?.([])} style={{ opacity: 0.5 }}>{placeholder}</MenuItem>
      )}
      {items?.map((item) => (
        <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelectComponent;
