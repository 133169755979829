import { useState, Dispatch, SetStateAction, useEffect } from "react";
import ModalComponent from "@components/modal/Modal.component";
import { useTranslation } from "react-i18next";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import AIOButtonComponent from "@components/Button.component";
import { adDetailsService } from "@store/ads/details";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";
import { jobBoardsService } from "@store/jobBoards";
import { SelectItem } from "@components/input/Select.component";
import { Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";

interface EditSourceModalProps {
  adId?: string;
  applyInfoId: string | number;
  handleCloseModal: () => void;
  currentSource: SelectItem;
  handleChangeSource: Dispatch<SetStateAction<SelectItem | undefined>>;
}

export const EditSourceModal = (props: EditSourceModalProps) => {
  const { adId, applyInfoId, handleCloseModal, currentSource, handleChangeSource } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
	const [selectedJobBoard, setSelectedJobBoard] = useState<SelectItem>(currentSource);
  const [jobBoards, setJobBoards] = useState<SelectItem[]>([]);

  useEffect(() => {
    jobBoardsService.getJobBoards().subscribe({
      next: (j) => setJobBoards(j.map((j) => ({ label: j.name, value: j.id }))),
      error: (err) => enqueueSnackbar(err.text, err.options)
    });
  }, [enqueueSnackbar]);

  const handleConfirm = () => {
    if (!adId || !selectedJobBoard) return;
    setLoading(true);
    adDetailsService.editAdApplicationJobBoard(adId, applyInfoId, selectedJobBoard.value).pipe(finalize(() => setLoading(false)))
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => {
          enqueueSnackbar(t("ads.details.applyTab.success.jobBoardUpdated"), { variant: "success" });
          handleChangeSource(selectedJobBoard);
          handleCloseModal();
        }
      });
  }
  return (
    <ModalComponent
      handleClose={handleCloseModal}
      title={t("applications.editSource")}
      titleLeft
      content={
        <Stack spacing={3} py="30px" width={breakpointDownSM ? "100%" : "300px"}>
          <Stack>
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("ads.details.applyTab.selectSource")}
            </Typography>
            <SelectWithSearchComponent
              placeholder={t("ads.details.applyTab.otherSource")}
              handleChange={(selectItem) => selectItem && setSelectedJobBoard(selectItem)}
              items={jobBoards}
              value={selectedJobBoard} />
          </Stack>
        </Stack>
      }
      maxWidth={false}
      fullWidth={false}
      paddingButton="10px 0px 40px 0px"
      actions={(
        <AIOButtonComponent
          title={t("global.confirm")}
          variant="contained"
          ariaLabel="modal"
          onClick={handleConfirm}
          disabled={loading} />
      )}
    />
  );
};

export default EditSourceModal;