import { useState } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import { useTranslation } from "react-i18next";
import { AdDetailsDetailsModel, adDetailsService } from "@store/ads/details";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

interface AiCriteriaEvaluationModalProps {
  handleCloseModal: () => void;
  adDetails: AdDetailsDetailsModel;
  hasSkipButton?: boolean;
}

const AiCriteriaEvaluationModal = (props: AiCriteriaEvaluationModalProps) => {
  const { handleCloseModal, adDetails, hasSkipButton } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [currentCriteria, setCurrentCriteria] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [savedCriterias, setSavedCriterias] = useState<string[]>(adDetails.criterias ?? []);

  const [hasInputError, setHasInputError] = useState(false);

  const handleSaveCriterias = () => {
    setLoading(true);
    adDetailsService
      .updateAd(adDetails.id, { criterias: savedCriterias })
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => handleCloseModal(),
      });
  };

  const checkCriteriaLimit = (): boolean => {
    if (savedCriterias.length >= 5) {
      enqueueSnackbar(t("ads.details.applyTab.aiSorting.criteriaLimitReached"), { variant: "warning" });
      return true;
    }
    return false;
  };

  const addCriteria = (value: string) => {
    if (checkCriteriaLimit()) return;
    const savedCriteriasLower = savedCriterias.map((c) => c.toLowerCase());
    if (value !== "" && !savedCriteriasLower.includes(value.toLowerCase())) setSavedCriterias((prevState) => [...prevState, value]);
    else setHasInputError(true);
  };

  const criteriasSuggestions = [
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria1"),
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria2"),
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria3"),
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria4"),
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria5"),
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria6"),
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria7"),
    t("ads.details.applyTab.aiSorting.defaultsCriterias.criteria8"),
  ];

  return (
    <ModalComponent
      handleClose={handleCloseModal}
      title={t("ads.details.applyTab.aiSorting.criteriaSortByAi")}
      maxWidth="sm"
      content={
        <Stack width="100%" pt="20px" pb="9px">
          <Typography textAlign="center" fontSize={16} fontWeight={500} color={Colors.primaryText}>
            {t("ads.details.applyTab.aiSorting.criteriasModalDescription")}
          </Typography>
          <Typography textAlign="center" fontWeight={400} pt="20px" pb="10px" color={Colors.secondaryText}>
            {t("ads.details.applyTab.aiSorting.criteriasSuggestions")}
          </Typography>
          <Stack width="100%" direction="row" justifyContent="center" flexWrap="wrap" gap="10px" pb="20px">
            {criteriasSuggestions.map((criteria) => (
              <Chip
                key={criteria}
                label={criteria}
                variant={"outlined"}
                color={savedCriterias.includes(criteria) ? "primary" : "default"}
                onClick={() => {
                  if (!savedCriterias.includes(criteria)) {
                    if (checkCriteriaLimit()) return;
                    setSavedCriterias((prevState) => [...prevState, criteria]);
                  } else {
                    setSavedCriterias(savedCriterias.filter((c) => c !== criteria));
                  }
                }}
              />
            ))}
          </Stack>
          <Stack width="100%" direction="row" justifyContent="center" alignItems="center" flexWrap="wrap" gap="10px">
            <AIOTextfieldComponent
              placeholder={t("ads.details.applyTab.aiSorting.addCriteria")}
              value={currentCriteria}
              sx={{ width: "400px", paddingY: "2.5px" }}
              onChange={(value) => {
                if (hasInputError) setHasInputError(false);
                setCurrentCriteria(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (checkCriteriaLimit()) return;
                  if (currentCriteria.trim() !== "") addCriteria(currentCriteria.trim());
                  setCurrentCriteria("");
                }
              }}
              error={hasInputError ? t("ads.details.applyTab.aiSorting.criteriaAlreadyAdded") : undefined}
            />
            <AIOButtonComponent
              title={t("global.add")}
              variant="contained"
              onClick={() => {
                if (savedCriterias.length >= 5) {
                  return enqueueSnackbar(t("ads.details.applyTab.aiSorting.criteriaLimitReached"), { variant: "warning" });
                }
                if (currentCriteria.trim() !== "") addCriteria(currentCriteria.trim());
                setCurrentCriteria("");
              }}
            />
          </Stack>
          {savedCriterias.length > 0 && (
            <Stack width="100%" direction="row" justifyContent="center" flexWrap="wrap" gap="10px" pt="30px">
              {savedCriterias.map((criteria) => (
                <Chip
                  key={criteria}
                  label={criteria}
                  variant="outlined"
                  color="primary"
                  onDelete={() => {
                    setSavedCriterias(savedCriterias.filter((c) => c !== criteria));
                  }}
                />
              ))}
            </Stack>
          )}
        </Stack>
      }
      actions={
        <>
          {hasSkipButton && <AIOButtonComponent title={t("global.skip")} variant="text" onClick={handleCloseModal} disabled={loading} />}
          <AIOButtonComponent title={t("global.save")} variant="contained" onClick={handleSaveCriterias} disabled={loading} />
        </>
      }
    />
  );
};
export default AiCriteriaEvaluationModal;
