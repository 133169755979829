import { select } from "@ngneat/elf";
import { selectAllEntitiesApply, UIEntitiesRef } from "@ngneat/elf-entities";
import { selectPaginationData } from "@ngneat/elf-pagination";
import { getAdsDataSource, getAdsStatsDataSource, getPaginatedAdsDataSource } from "@store/ads/ads.requests";
import { adsStore } from "@store/ads/ads.store";

export class AdsQuery {
  store = adsStore;

  stats$ = getAdsStatsDataSource.data$();

  ads$ = getAdsDataSource.data$();
  paginatedAds$ = getPaginatedAdsDataSource.data$();
  adsPaginationData$ = this.store.pipe(selectPaginationData());
  adsToPublish$ = this.store.pipe(select((state) => state.adsToPublish));
  loadingAdsToPublish$ = this.store.pipe(select((state) => state.loadingAdsToPublish));
  adsToPublishFilters$ = this.store.pipe(select((state) => state.adsToPublishFilters));
  adsToPublishSelected$ = this.store.pipe(select((state) => state.adsToPublishSelected));
  multiDiffusionAds$ = this.store.pipe(select((state) => state.multiDiffusionAds));
  multiDiffusionAdsFilters$ = this.store.pipe(select((state) => state.multiDiffusionAdsFilters));
  multiDiffusionAdsStats$ = this.store.pipe(select((state) => state.multiDiffusionAdsStats));
  removeOneMultiDiffusionAd$ = (multiDiffusionAdId: string) =>
    this.store.update((state) => ({
      ...state,
      multiDiffusionAds: {
        ...state.multiDiffusionAds,
        data: state.multiDiffusionAds.data.filter((ad) => ad.id !== multiDiffusionAdId),
      },
    }));

  selectedAds$ = this.store.pipe(
    selectAllEntitiesApply({
      mapEntity: (e) => e.id,
      filterEntity: (e) => e.selected,
      ref: UIEntitiesRef,
    })
  );

  filters$ = this.store.pipe(select((state) => state.filters));
  sort$ = this.store.pipe(select((state) => state.sort));

  get filters() {
    return this.store.getValue().filters;
  }
}

export const adsQuery = new AdsQuery();
