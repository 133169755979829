import { SelectItem } from "@components/input/Select.component";
import { AffiliateLightModel } from "@store/entities/afiliates";
import I18nUtils from "@utils/i18n.utils";
import { BoostStatus } from "../boosts";
import { Colors } from "@constants/colors.constant";
import { MultiDiffusionActionsEnum } from "@screens/auth/admin/multiDiffusion/components/MultiDiffusionAdsTable";
import { CodeTypeExpProEnum, TypeDureeExpProEnum, HorairesTypeEnum, ConditionsExerciseEnum } from "./details";

export enum AdsSortField {
  NAME = "name",
  LOCATION = "location",
  REFERENCE = "reference",
  CREATION = "publicationDate",
}

export enum MultiDiffusionAdMediaEnum {
  FRANCE_TRAVAIL = "FRANCE_TRAVAIL",
}

export namespace AdsSortField {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("ads.sort.byName"), value: AdsSortField.NAME },
    { label: I18nUtils.t("ads.sort.byReference"), value: AdsSortField.REFERENCE },
    { label: I18nUtils.t("ads.sort.byLocation"), value: AdsSortField.LOCATION },
    { label: I18nUtils.t("ads.sort.byPublicationDate"), value: AdsSortField.CREATION },
  ];
}

export interface AdsSort {
  field: AdsSortField;
}

export namespace AdsFilterArchived {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("ads.filters.online"), value: false },
    { label: I18nUtils.t("ads.filters.archived"), value: true },
  ];
}

export interface AdsFilters {
  search?: string;
  affiliateIds?: SelectItem[];
  aioCandidateJobName?: string;
  aioCandidateJobLocation?: string;
  aioCandidateJobReference?: string;
  isArchived?: boolean;
}

export interface AdsToPublishFilters {
  affiliateIds: SelectItem[];
  contractType: SelectItem[];
  location: SelectItem[];
  excludedAdIds: string[];
  commaSeparatedReferences: string;
}

export interface MultiDiffusionAdsFilters {
  status?: MultiDiffusionAdStatusEnum;
  affiliateIds: SelectItem[];
  search?: string;
}

export interface AdStatProgress {
  current: number;
  previous: number;
}

export interface AdStats {
  nbViews?: AdStatProgress;
  uniqueVisitor?: AdStatProgress;
  engagementRate?: AdStatProgress;
  bounceRate?: AdStatProgress;
  applications?: AdStatProgress;
  quality?: AdStatProgress;
}

export interface AdStatsReport {
  nbViews?: number;
  engagementRate?: number;
  quality?: number;
}

export interface AdUi {
  id: string;
  selected: boolean;
}

export interface BoostAdModel {
  id: string;
  location: string;
  name: string;
  reference: string;
  description: string;
  affiliate: AffiliateLightModel;
  applications?: {
    aioApplications: number;
    powerBiApplications: number;
  };
  publicationDate: Date;
  managedByTwinin: boolean;
}

export interface AdModel {
  id: string;
  department: string;
  location: string;
  name: string;
  reference: string;
  affiliate: AffiliateLightModel;
  applications?: {
    aioApplications: number;
    powerBiApplications: number;
  };
  managedByTwinin: boolean;
  isBoosted: boolean;
  reportStats?: AdStatsReport;
  boostStatus?: BoostStatus;
  publicationDate: Date;
  inDistress: boolean;
  isCustom: boolean;
  lastSeen: Date;
  domain?: string[];
  contractType?: string;
  company?: string;
  archivedAt?: Date;
  hasMultiDiffusionAdFor?: MultiDiffusionAdMediaEnum[];
}

export enum MultiDiffusionAdStatusEnum {
  PUBLISHED = "PUBLISHED",
  PROGRAMMED = "PROGRAMMED",
  PENDING = "PENDING",
  TO_BE_CORRECTED = "TO_BE_CORRECTED",
  CLOSED = "CLOSED",
}

export interface JtmoErrorDto {
  codeMotif: number;
  libelleMotif: string;
  idMouvement: number;
}

export enum MultiDiffusionSalaryTypeEnum {
  M12 = "M12",
  M13 = "M13",
  A12 = "A12",
  A13 = "A13",
}

export interface MultiDiffusionAdModel {
  id: string;
  originalAnnouncementId: string;
  affiliate: string;
  name: string;
  reference?: string | null;
  location?: string | null;
  department?: string | null;
  region?: string | null;
  description?: string | null;
  companyInformation?: string | null;
  companyName?: string | null;
  profile?: string | null;
  advantages?: string | null;
  contractType?: string | null;
  contractDuration?: string | null;
  workingTime?: string | null;
  minSalary?: number | null;
  maxSalary?: number | null;
  slogan?: string | null;
  url?: string | null;
  jtmoError?: JtmoErrorDto | null;
  status: MultiDiffusionAdStatusEnum;
  idMouvement?: string | null;
  partyId?: string | null;
  codeRome?: string | null;
  appellationRome?: string | null;
  programedAt?: Date | null;
  media: MultiDiffusionAdMediaEnum | null;
  refOffrePE?: string | null;
  codeTypeExpPro?: CodeTypeExpProEnum | null;
  dureeMinExpPro?: number | null;
  typeDureeExpPro?: TypeDureeExpProEnum | null;
  horairesTypes?: HorairesTypeEnum[] | null;
  conditionsExercise?: ConditionsExerciseEnum[] | null;
  salaryType?: MultiDiffusionSalaryTypeEnum | null;
}

export interface ManageMultiDiffusionAd extends Partial<MultiDiffusionAdModel> {
  id: string;
}

export type MultiDiffusionAdStats = Record<MultiDiffusionAdStatusEnum, number>;

export interface MultiDiffusionStatusDetail {
  status: MultiDiffusionAdStatusEnum;
  statusLabel: string;
  actions: MultiDiffusionActionsEnum[];
  color: string;
}

export namespace MultiDiffusionAdStatusDetails {
  export const getStatusDetails = (status: MultiDiffusionAdStatusEnum): MultiDiffusionStatusDetail => {
    switch (status) {
      case MultiDiffusionAdStatusEnum.PUBLISHED:
        return {
          status,
          statusLabel: I18nUtils.t("multiDiffusion.tab.adStatus.published"),
          actions: [
            MultiDiffusionActionsEnum.EDIT,
            MultiDiffusionActionsEnum.LINK,
            MultiDiffusionActionsEnum.REFRESH,
            MultiDiffusionActionsEnum.CLOSE,
          ],
          color: Colors.marine,
        };

      case MultiDiffusionAdStatusEnum.PROGRAMMED:
        return {
          status,
          statusLabel: I18nUtils.t("multiDiffusion.tab.adStatus.programmed"),
          actions: [MultiDiffusionActionsEnum.EDIT, MultiDiffusionActionsEnum.DELETE],
          color: Colors.marine,
        };

      case MultiDiffusionAdStatusEnum.TO_BE_CORRECTED:
        return {
          status,
          statusLabel: I18nUtils.t("multiDiffusion.tab.adStatus.toCorrect"),
          actions: [MultiDiffusionActionsEnum.EDIT, MultiDiffusionActionsEnum.DELETE],
          color: Colors.yellow,
        };

      case MultiDiffusionAdStatusEnum.PENDING:
        return {
          status,
          statusLabel: I18nUtils.t("multiDiffusion.tab.adStatus.inAnalysis"),
          actions: [MultiDiffusionActionsEnum.EDIT, MultiDiffusionActionsEnum.DELETE],
          color: Colors.orange,
        };

      case MultiDiffusionAdStatusEnum.CLOSED:
        return {
          status,
          statusLabel: I18nUtils.t("multiDiffusion.tab.adStatus.closed"),
          actions: [MultiDiffusionActionsEnum.REFRESH],
          color: Colors.boostCard.error,
        };

      default:
        return {
          status,
          statusLabel: I18nUtils.t("multiDiffusion.tab.adStatus.pending"),
          actions: [],
          color: Colors.primary,
        };
    }
  };

  export const getRegionOptions = (): SelectItem[] => {
    return [
      { value: "84", label: "Auvergne-Rhône-Alpes" },
      { value: "27", label: "Bourgogne-Franche-Comté" },
      { value: "53", label: "Bretagne" },
      { value: "24", label: "Centre-Val de Loire" },
      { value: "94", label: "Corse" },
      { value: "44", label: "Grand Est" },
      { value: "32", label: "Hauts-de-France" },
      { value: "11", label: "Île-de-France" },
      { value: "28", label: "Normandie" },
      { value: "75", label: "Nouvelle-Aquitaine" },
      { value: "76", label: "Occitanie" },
      { value: "52", label: "Pays de la Loire" },
      { value: "93", label: "Provence-Alpes-Côte d'Azur" },
    ];
  };
}
