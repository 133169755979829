export interface ManageAIPromptModel extends Partial<AIPromptModel> {}

export enum AIPromptTypeEnum {
  PROFILE_SEARCH_KEYWORD = "PROFILE_SEARCH_KEYWORD",
  SLOGAN = "SLOGAN",
  HASHTAG = "HASHTAG",
  LINKEDIN_POST = "LINKEDIN_POST",
  TWILIO_CONVERSATION = "TWILIO_CONVERSATION",
  JOB_INTERVIEW_CONVERSATIONAL = "JOB_INTERVIEW_CONVERSATIONAL",
}

export namespace AIPromptTypeFunctions {
  export const variables = {
    [AIPromptTypeEnum.PROFILE_SEARCH_KEYWORD]: ["nom_annonce", "description_annonce"],
    [AIPromptTypeEnum.SLOGAN]: ["nom_annonce", "description_annonce"],
    [AIPromptTypeEnum.HASHTAG]: ["nom_annonce", "description_annonce"],
    [AIPromptTypeEnum.LINKEDIN_POST]: ["nom_annonce", "description_annonce", "url_annonce"],
  };
}

export interface AIPromptModel {
  id: string;
  systemContent: string;
  userContent: string;
  type: AIPromptTypeEnum;
  model: string;
}
