export enum FranceTravailAnnulationMotifEnum {
	ODB = 'ODB', // Offre en doublon
	OLI = 'OLI', // Offre non conforme
	CON = 'CON', // Suite au contact avec l'employeur
	PBT = 'PBT', // Pour pallier un problème technique
	CPE = 'CPE', // Le poste a été pourvu par un candidat proposé par France Travail
	SPE = 'SPE', // Par un candidat ayant pris connaissance de l'offre sur francetravail.fr
	SAN = 'SAN', // Le poste a été pourvu sans l'aide de France Travail
	RAD = 'RAD', // Le recrutement a été annulé ou différé
	PSD = 'PSD', // Date de prochain suivi dépassée
	PSA = 'PSA', // Date de prochain suivi atteinte
	QMA = 'QMA', // Dépubliée pour quota de MER atteint
	TRA = 'TRA', // Réaffectée à l'agence
	AND = 'AND', // Clôturée DOL
	SHM = 'SHM', // Clôturée pour cause de poste(s) satisfait(s) hors MER+
	SPM = 'SPM', // Clôturée pour cause de poste(s) satisfait(s) par MER+
	PEI = 'PEI', // Clôturée pour cause de poste(s) pourvu(s) en interne
	DIS = 'DIS', // Retiré suite à la disparition du besoin
	ABD = 'ABD', // Retiré faute de candidat
	PCA = 'PCA', // Dépublication de l'offre : Plafond de candidatures internet à traiter atteint
	ODI = 'ODI', // Offre diffusée sur internet
	ROL = 'ROL', // Clôturée pour cause d'offre non conforme
	UCS = 'UCS', // URL site entreprise suspecte
	OFR = 'OFR', // Offre frauduleuse
	RER = 'RER', // Espace certifié, offre publiée
	SER = 'SER', // Dépubliée pour espace bloqué
	CFR = 'CFR', // Clôturée pour suspicion de fraude
	CCE = 'CCE', // Candidatures en cours d'examen
	DEC = 'DEC', // Décision en cours
	RSP = 'RSP', // Recrutement suspendu provisoirement
	AUT = 'AUT', // Autre
	ACT = 'ACT', // Actualisation des dates
	CLA = 'CLA', // Clôture automatique suite à fin de publication de l'offre
	SAP = 'SAP', // Dépubliée suite à Mesure AFPR-POEI
	QOK = 'QOK', // Etat qualité Ok sur offre non éligible ou hors contexte VAO
	QKO = 'QKO', // Etat qualité ko sur offre non éligible ou hors contexte VAO
	ALQ = 'ALQ', // Alertes qualités signalées à la validation
	QOE = 'QOE', // Etat qualité Ok sur offre éligible VAO
	QKE = 'QKE', // Etat qualité Ko sur offre éligible VAO
	COK = 'COK', // Le recruteur a consenti à la diffusion de ses données personnelles sur son offre d'emploi
	CKO = 'CKO', // Le recruteur souhaite publier son offre d'emploi sans ses données personnelles
	FPA = 'FPA', // Dépubliée pour date de fin de publication atteinte
	URL = 'URL', // URL de candidature non valide
	URS = 'URS', // URL de candidature suspecte
	UNV = 'UNV', // URL non valide
	REA = 'REA', // Espace recruteur certifié par l'administrateur
	EAC = 'EAC', // Espace recruteur à certifier par l'administrateur
	DNS = 'DNS'  // Reprise de stock pour DNS
}