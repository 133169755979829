import AIOTabComponent from "@components/AIOTab.component";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import ClientsFilterComponent from "@components/input/ClientsFilterComponent";
import { Colors } from "@constants/colors.constant";
import { Box, Stack, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "@store/session";
import { useTranslation } from "react-i18next";
import MultiDiffusionAdsDetails from "./components/MultiDiffusionAdsDetails";
import MultiDiffusionAdsTable from "./components/MultiDiffusionAdsTable";
import MultiDiffusionModalsManager from "@components/modal/publishAds/MultiDiffusionModalsManager";
import { adsQuery, adsService, MultiDiffusionAdStatusEnum } from "@store/ads";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useSnackbar } from "notistack";
import debounce from "lodash/debounce";
import AIOButtonComponent from "@components/Button.component";
import useMultiDiffusionAdStore, { MultiDiffusionModalEnum } from "@store/multiDiffusionAd/multiDiffusionAd.store";

const MultiDiffusion = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const { enqueueSnackbar } = useSnackbar();

  const [affiliateIds] = useObservable(sessionQuery.affiliateIds$);
  const [multiDiffusionAdsStats] = useObservable(adsQuery.multiDiffusionAdsStats$);

  const { setModalTypeOpened } = useMultiDiffusionAdStore();

  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    const subscription = adsService.getMultiDiffusionAdsStats({ affiliateIds }).subscribe({
      next: () => {},
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    return () => subscription.unsubscribe();
  }, [affiliateIds, enqueueSnackbar]);

  const tabValueToStatus = (value: number): MultiDiffusionAdStatusEnum | undefined => {
    return value === 1
      ? MultiDiffusionAdStatusEnum.PUBLISHED
      : value === 2
      ? MultiDiffusionAdStatusEnum.TO_BE_CORRECTED
      : value === 3
      ? MultiDiffusionAdStatusEnum.PROGRAMMED
      : value === 4
      ? MultiDiffusionAdStatusEnum.PENDING
      : value === 5
      ? MultiDiffusionAdStatusEnum.CLOSED
      : undefined;
  };

  const handleTabChange = useCallback((value: number) => {
    setTabValue(value);
    adsService.setMultiDiffusionFilters({ status: value === 0 ? undefined : tabValueToStatus(value) });
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((value?: string) => {
        adsService.setMultiDiffusionFilters({ search: value || "" });
      }, 300),
    []
  );

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 0 : 4}
      flex={1}
      width={"100%"}
      overflow="auto">
      <Stack
        width={"100%"}
        height={"100%"}
        direction={breakpointDownSM ? "column" : "row"}
        alignItems={breakpointDownSM ? "flex-start" : "center"}
        spacing={2}
        justifyContent="flex-start">
        <Stack alignItems="flex-start" justifyContent="flex-start" width={"100%"} height={"100%"} gap={2} pb={"30px"}>
          <Stack direction="row" alignItems="center" gap={2} width={"100%"} justifyContent={"space-between"}>
            <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight={700}>
              {t(`multiDiffusion.title`)}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              style={{ cursor: "pointer", borderRadius: "10px", backgroundColor: alpha(Colors.primary, 0.1) }}>
              <Box width="300px">
                <ClientsFilterComponent
                  values={affiliateIds}
                  handleChange={(value) => adsService.setMultiDiffusionFilters({ affiliateIds: value })}
                />
              </Box>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={2} width={"100%"} justifyContent={"space-between"}>
            <MultiDiffusionAdsDetails
              value={multiDiffusionAdsStats?.[MultiDiffusionAdStatusEnum.PUBLISHED] ?? 0}
              color={Colors.marine}
              icon={<img height="32px" src="/images/published.png" alt="published" />}
              content={t(`multiDiffusion.adsDetails.published`)}
            />
            <MultiDiffusionAdsDetails
              value={multiDiffusionAdsStats?.[MultiDiffusionAdStatusEnum.TO_BE_CORRECTED] ?? 0}
              color={Colors.yellow}
              icon={<img height="30px" src="/images/corrected.png" alt="corrected" />}
              content={t(`multiDiffusion.adsDetails.corrected`)}
            />
            <MultiDiffusionAdsDetails
              value={multiDiffusionAdsStats?.[MultiDiffusionAdStatusEnum.PENDING] ?? 0}
              color={Colors.orange}
              icon={<img width="30px" src="/images/analysed.png" alt="analysed" />}
              content={t(`multiDiffusion.adsDetails.analysed`)}
            />
            <MultiDiffusionAdsDetails
              value={multiDiffusionAdsStats?.[MultiDiffusionAdStatusEnum.CLOSED] ?? 0}
              color={Colors.boostCard.error}
              icon={<img height="32px" src="/images/closed.png" alt="closed" />}
              content={t(`multiDiffusion.adsDetails.closed`)}
            />
          </Stack>
          <Stack alignItems="center" justifyContent={"center"} width={"100%"}>
            <AIOTabComponent
              tabs={[
                t("multiDiffusion.tabs.all"),
                t("multiDiffusion.tabs.published"),
                t("multiDiffusion.tabs.toCorrect"),
                t("multiDiffusion.tabs.programmed"),
                t("multiDiffusion.tabs.waiting"),
                t("multiDiffusion.tabs.closed"),
              ]}
              value={tabValue}
              handleChange={handleTabChange}
            />
          </Stack>
          <Stack width={"100%"} direction="row" alignItems="flex-end" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={2}>
              <AIOButtonComponent
                title={t("multiDiffusion.tab.adStatus.toCorrectModal.refreshAdsModal.title")}
                variant="contained"
                onClick={() => setModalTypeOpened(MultiDiffusionModalEnum.REFRESH_ADS)}
              />
              <AIOButtonComponent
                title={t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdsModal.title")}
                variant="contained"
                onClick={() => setModalTypeOpened(MultiDiffusionModalEnum.CLOSE_ADS)}
              />
            </Stack>
            <AIOSearchComponent placeholder={t("multiDiffusion.search")} onChange={debouncedSearch} />
          </Stack>
          <Stack width={"100%"} flex={1}>
            <MultiDiffusionAdsTable />
          </Stack>
          <MultiDiffusionModalsManager />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MultiDiffusion;
